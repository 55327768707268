import React from 'react'
import { Collapse, Space, Select, Slider } from 'antd'
import InputColor from '../../../InputColor'

const states = [
    { name: 'default', label: 'Affichage normal' },
    { name: 'hover', label: 'Au survol de la souris' },
    { name: 'select', label: 'Lorsque l\'étape est sélectionné' }
]

const { Panel } = Collapse;


export default function EditPathContent({ style, handleSave }) {

    function updateTypeData(type, data) {
        handleSave({ ...style, [type]: { ...style[type], ...data } })
    }

    function updateDash(type, index, value) {
        let dash = [...style[type].dash]
        dash[index] = value
        handleSave({ ...style, [type]: { ...style[type], dash: dash } })

    }

    return (
        <div style={{ margin: '1em 0' }}>
            <h4 style={{ textAlign: 'center' }}>Personnaliser le rendu de l'itinéraire</h4>
            <Collapse defaultActiveKey={['default']} ghost expandIconPosition="right" accordion={true}>
                {
                    states.map(item => (
                        <Panel
                            header={
                                <div style={{ position: 'relative' }}>
                                    <strong>{item.label}</strong>
                                    <svg width="100%" height={style[item.name].width}
                                        style={{ overflow: 'visible' }}
                                        version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <line strokeDasharray={`${style[item.name].dash[0]}, ${style[item.name].dash[1]}`}
                                            stroke={style[item.name].color}
                                            strokeWidth={style[item.name].width}
                                            x1="0" y1="1" x2="100%" y2="1"
                                            strokeLinecap={style[item.name].cap} />
                                    </svg>
                                </div>
                            }
                            key={item.name}>
                            <Space direction="vertical" style={{width : '100%'}}>
                                <div>
                                    <div>Epaisseur: </div>
                                    <div>
                                        <Slider value={style[item.name].width || 5}
                                            max={10} min={1}
                                            onChange={(value) => updateTypeData(item.name, { width: value })} />
                                    </div>
                                </div>
                                <div>
                                    <div>Longueur des traits: </div>
                                    <div>
                                        <Slider value={style[item.name].dash[0] || 5}
                                            max={15} min={1}
                                            onChange={(value) => updateDash(item.name, 0, value)} />
                                    </div>
                                </div>
                                <div>
                                    <div>Espacement des traits: </div>
                                    <div>
                                        <Slider value={style[item.name].dash[1] || 5}
                                            max={15} min={1}
                                            onChange={(value) => updateDash(item.name, 1, value)} />
                                    </div>
                                </div>

                                <div>
                                    <div>Couleur: </div>
                                    <div>
                                        <InputColor
                                            value={style[item.name].color}
                                            onChange={(value) => updateTypeData(item.name, { color: value })}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>Extrémités des traits: </div>
                                    <div>
                                        <Select
                                            value={style[item.name].cap}
                                            onChange={(value) => updateTypeData(item.name, { cap: value })}
                                            width={200}
                                        >
                                            <Select.Option value={'round'}>Arrondi</Select.Option>
                                            <Select.Option value={'square'}>Carré</Select.Option>
                                            <Select.Option value={'butt'}>Butt</Select.Option>
                                        </Select>
                                    </div>
                                </div>
                            </Space>
                        </Panel>
                    ))
                }
            </Collapse >
        </div>
    )
}
