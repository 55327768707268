import React from "react";
import Icon, { CarOutlined, RocketOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import {ReactComponent as Marker} from '../../assets/marker-alt.svg'
import {ReactComponent as Path} from '../../assets/path.svg'
import {ReactComponent as Api} from '../../assets/api-connect.svg'
import {ReactComponent as Folder} from '../../assets/folder-plus.svg'


export function resolveFeatureIcon(type, feature = null) {
    if (type === 'layer') return <Icon component={Folder}/>;
    if (type === 'marker') return <Icon component={Marker} />;
    if (type === 'path') return <Icon component={Path} />;
    if (type === 'api') return <Icon component={Api} />;
    if (type === 'step' && feature?.vehicle === 'car') return <CarOutlined />;
    if (type === 'step' && feature?.vehicle === "bike") return <RocketOutlined />;
    if (type === 'step' && feature?.vehicle === "foot") return <ShoppingCartOutlined />;

    return null
}


export const baseSettings = {
	"fileFormat": "hapimap/1.0",
	"ctime": "2021-04-01 12:00:00",
	"mtime": "2021-05-04 10:45:00",
	"label": {
		"fr": "Popup test"
	},
	"raster": "maptiler.streets",
	"zoom": 55,
    "center": [
        48.8566969,
        2.3514616
    ],
	"bounds": null,
	"controls": {
		"layerswitcher": false,
		"tilesetswitcher": false,
		"zoom": false
	},
	"styles": {},
	"features": []
}

export const rasterList = [
	"default",
	"light",
	"dark",
	"osm",
	"osmfr",
	"maptiler.basic",
	"maptiler.bright",
	"maptiler.outdoor",
	"maptiler.pastel",
	"maptiler.hybrid",
	"maptiler.streets",
	"maptiler.topo",
	"maptiler.topographique",
	"maptiler.toner",
	"maptiler.voyager",
]

export function getCluster(amount) {
	let points = []
	let layer = {
		"id": 1646905408802,
		"type": "layer",
		"visible": true,
		"label": {
			"fr": "Nouveaux dossier"
		},
		"features": [],
		"cluster": true,
		"style": { "marker": null },
	}

	for (let index = 0; index < amount; index++) {
		points.push({
			"id": 'dummy' + index,
			"type": "marker",
			"visible": true,
			"style": null,
			"tooltip": { "visible": false, "style": null },
			"popup": { "visible": false, "style": null },
			"label": { "fr": "" },
			"baseline": { "fr": "" },
            "coordinate": [
                48.8566969,
                2.3514616
            ]
		})
	}

	layer.features = points;

	return [layer]

}

export function getPoint(popup, tooltip) {
	return {
		"id": (new Date()).getTime(),
		"type": "marker",
		"visible": true,
		"style": null,
		"tooltip": { "visible": tooltip, "style": null },
		"popup": { "visible": popup, "style": null },
		"label": { "fr": "Ceci est le label" },
		"baseline": { "fr": "Ceci est la baseline" },
        // "link": {"label": {"fr": "Ceci est un lien"}, "url": "www.mmcreation.com", "external": true},
        "text": {"fr":"Ceci est un texte"},
        "coordinate": [
            48.8566969,
            2.3514616
        ]
	}
}