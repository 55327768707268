import React from 'react';
import { Button, Radio, Space, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const ModeSelect = ({ advancedMode, setAdvancedMode }) => {

    return (
        <div className='pd-default text-center' >
            <Radio.Group value={advancedMode} onChange={e => setAdvancedMode(e.target.value)} buttonStyle="solid">
                <Radio.Button value="default">Normal</Radio.Button>
                <Radio.Button value="hover">Survol</Radio.Button>
                <Radio.Button value="select">Sélection</Radio.Button>
            </Radio.Group>
        </div>
    );
};

export default ModeSelect;