import React from 'react';
import {Route} from 'react-router-dom';
import EditorLayout from './EditorLayout.js';
import {EditorProvider} from "../providers/EditorProvider";

const EditorRoute = ({component: Component, ...rest}) => {

    const renderer = (sidebar, props) => {
            return <EditorLayout {...props}><Component {...props} /></EditorLayout>
    }

    return (
        <EditorProvider>
            <Route {...rest} render={renderer.bind(null)}/>
        </EditorProvider>
    );
};

export default EditorRoute;
