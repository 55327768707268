import React, { useEffect, useState } from 'react'
import { useEditorContext } from '../../providers/EditorProvider';
import { Radio } from 'antd'

import Icon from "@ant-design/icons";
import { ReactComponent as Laptop } from '../../assets/laptop.svg'
import { ReactComponent as Tablet } from '../../assets/tablet.svg'
import { ReactComponent as Mobile } from '../../assets/mobile.svg'

let currentFeatures = [];
let currentSelection = null;

export default function Map({ mapId = null, setSelectedFeature, selectedFeature, view, isDrawing, setIsDrawing }) {

    const [editorState, editorDispatch] = useEditorContext()
    const { buildMapJson, deepCopy, setMapInstance, getFlatFeaturesArray, restartMap, updateFeature } = editorDispatch;
    const { features, hapimap } = editorState;

    const [previewJson, setPreviewJson] = useState(null)
    const [blockCenter, setBlockCenter] = useState(false)
    const [previewMode, setPreviewMode] = useState('desktop')

    useEffect(() => {
        currentFeatures = [...features]
        checkSelection()
    }, [features])

    useEffect(() => {
        let allowPanels = view.match(/panels|publish/)
        let map = buildMapJson(!!allowPanels);
        // console.log('map.bounds', map.bounds)
        // allow to keep the map on the same position even if state has different values
        if (hapimap) {
            map.zoom = hapimap.getZoom();
            map.center = hapimap.getCenter();
            map.bounds = null
        }
        //force json difference to display real version of the map
        map.v = (new Date()).getTime()
        setPreviewJson(JSON.stringify(map))
    }, [editorState.reloadMap, view])

    // force a map restart on previewMode change (only in the "publish" view)
    useEffect(() => {
        if (view.match(/panels|publish/)) {
            editorState?.hapimap?.setPreviewMode(previewMode)
            setTimeout(() => {
                restartMap() // restart only when css animation is done
            }, 300);
        } else {
            editorState?.hapimap?.setPreviewMode()
        }
    }, [view, previewMode])


    useEffect(() => {

        if (!editorState.restartMap) return
        let allowPanels = view.match(/panels|publish/)
        let map = buildMapJson(!!allowPanels);
        checkSelection()
        //allow to reload map even if the state didnt change
        map.restart = editorState.restartMap
        setPreviewJson(JSON.stringify(map))
    }, [editorState.restartMap])


    useEffect(() => {

        if (!previewJson) return
        if (hapimap) {
            //console.log(previewJson)
            setTimeout(() => {
                hapimap.load(previewJson, true)
                setTimeout(() => {
                    hapimap.setRaster(editorState.raster)
                }, 10);
            }, 50)
            return;
        }
        else
            initMap()

        return () => {
            if (hapimap) {
                console.log('Editor "hapimap" instance DISMOUNT')
                hapimap.dismount()
            }
        }
    }, [previewJson])

    // Effect triggerred on Feature Selection
    useEffect(() => {
        currentSelection = selectedFeature
        if (hapimap) {
            // on ferme tout les popup
            hapimap.findHandlersByType('marker').forEach(h => {
                h.closePopup()
                h.setDragndrop(false)
            })
            if (selectedFeature) {
                selectPoint(hapimap, selectedFeature)
            }
        }
    }, [selectedFeature])

    function selectPoint(map, selectedFeature) {
        let fid = selectedFeature.id
        // on cherche la feature
        let h = map.findHandler(fid)

        if (h) {
            // console.log('h', h);
            // si on la trouve on centre la vue et on sélectionne le point
            // le center // select peuvent être bloqué lors du drag pour eviter un changement de position de la carte ennuyeux
            if (!blockCenter) {
                h.select();
                h.center();
            }
            if (selectedFeature.type === 'marker') {
                h.openPopup()
                h.setDragndrop(true)
            }

        }
        setBlockCenter(false)
    }

    // keep selection updated with the latest feature
    function checkSelection() {
        if (currentSelection) {
            let flatFeatures = getFlatFeaturesArray(currentFeatures);
            let selectionMatch = flatFeatures.find(item => item.id === currentSelection.id)

            if (!selectionMatch) {
                return setSelectedFeature(null)
            }
            if (JSON.stringify(selectionMatch) !== JSON.stringify(currentSelection)) {
                return setSelectedFeature(selectionMatch)
            }
        }
    }

    function initMap() {
        let id = mapId ? "#" + mapId : '#map1'

        // console.log('Editor "hapimap" instance BUILDING')

        var hapimapLoader = new HapimapLoader(id);
        hapimapLoader.onLoad(function (hapimap) {

            // console.log('Editor "hapimap" instance LOADED')

            hapimap.on('map.load', () => {
                // allow to keep the point selected after reloading the map
                if (currentSelection) {
                    selectPoint(hapimap, currentSelection)
                }
                // console.log('Editor "hapimap" instance READY')
            });

            hapimap.on('feature.select', featureHandler => {
                const _features = getFlatFeaturesArray(currentFeatures);
                setSelectedFeature(_features.find(f => f.id === featureHandler.id));
                if (typeof featureHandler.center === 'center')
                    featureHandler.center()
            })

            hapimap.on('feature.moved', function (featureHandler) {
                // console.log('index.html : feature.moved detected', { featureHandler });
                const _features = getFlatFeaturesArray(currentFeatures);
                let updatedFeature = deepCopy(_features.find(f => f.id === featureHandler.id))

                if (updatedFeature) {
                    setBlockCenter(true)
                    //update feature and selected feature to avoid loosing data
                    updatedFeature.coordinate = featureHandler.getCoordinates()
                    updateFeature({ ...updatedFeature }, false, currentFeatures)
                }
            })

            hapimap.setLanguage(editorState.defaultLanguage);
            // console.log(previewJson)
            setTimeout(() => {
                hapimap.load(previewJson)
            }, 50);

            setMapInstance(hapimap)
        })
    }

    function handlePreviewChange(e) {
        if (isDrawing) {
            setIsDrawing(false)
        }
        setPreviewMode(e.target.value)
    }

    return (
        <div className={`map-container ${view.match(/panels|publish/) ? 'preview ' + previewMode : ''}`}>
            <div className='drawing-msg'>
                {
                    isDrawing &&
                    <p>Dessiner une zone sur la carte</p>
                }
            </div>
            <div className="map" id={mapId ? mapId : "map1"} />
            {
                view.match(/panels|publish/) &&
                <div className='display-mode'>
                    <Radio.Group size="small" value={previewMode} onChange={handlePreviewChange} buttonStyle="solid">
                        <Radio.Button value="desktop"><Icon component={Laptop} /></Radio.Button>
                        <Radio.Button value="tablet"><Icon component={Tablet} /></Radio.Button>
                        <Radio.Button value="mobile"><Icon component={Mobile} /></Radio.Button>
                    </Radio.Group>
                </div>
            }
        </div>
    )
}
