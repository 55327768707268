import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Image, Row, Space } from "antd";
import Icon, { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as DeleteIcon } from "../../../assets/trash.svg";
import { Hapi_ImagePicker } from "../../ImagePicker";
import { useEditorContext } from "../../../providers/EditorProvider";
import TextInput from "../TextInput";

export default function FeatureVisuals({ feature, handleUpdate }) {
  const [editorState, editorDispatch] = useEditorContext();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);
  const [imagePicker, setImagePicker] = useState(null);
  const [localMedias, setLocalMedias] = useState(
    feature.media ? [...feature.media] : []
  );

  useEffect(() => {
    if (feature.media) {
      setLocalMedias([...feature.media]);
    } else {
      setLocalMedias([]);
    }
  }, [feature]);

  useEffect(() => {
    if (!imagePicker && editorState.imagePicker) {
      let param = {
        endpoint: editorState.imagePicker.endpoint,
        connector:
          editorState.imagePicker.connector === "hapidam"
            ? "hapidam"
            : "filemanager",
        token: editorState.imagePicker.token,
      };
      setImagePicker(new Hapi_ImagePicker(param));
    }
  }, []);

  function openImagePicker(e, index) {
    e.stopPropagation();

    imagePicker.close();
    // set image selection callback (after initialisation)
    // notice! callback can be set as a constructor parameter
    imagePicker.setCallback((image) => setSelectedImage(image, index));
    // finally open the image picker
    imagePicker.open();
  }

  function setSelectedImage(image, editingIndex) {
    //{url,name,description}
    let medias = [...localMedias];
    let newMedia = {
      url: image.url,
      alt: { fr: image.name },
      legend: { fr: image.description },
    };
    if (editingIndex >= localMedias.length) {
      medias.push(newMedia);
    } else {
      medias = medias.map((media, index) =>
        index === editingIndex ? newMedia : media
      );
    }
    handleUpdate({ media: medias });
    setLocalMedias(medias);
  }

  function handleVisibleChange(visible) {
    if (visible !== previewVisible) setPreviewVisible(visible);
  }

  function updateMediaText(data, editingIndex) {
    let newMedias = [...localMedias];
    newMedias = newMedias.map((media, index) => {
      return index === editingIndex ? { ...media, ...data } : { ...media };
    });
    //console.log(newMedias)
    handleUpdate({ media: newMedias });
    setLocalMedias(newMedias);
  }

  function showPreview(current) {
    setCurrentImg(current);
    setPreviewVisible(true);
  }

  if (!imagePicker) {
    return (
    <div className="pd-default">
      Des informations fournis par hapi sont manquantes
    </div>
    )
  }

  return (
    <>
      {localMedias.map((media, index) => (
        <div key={index + media.url} className="mb-default">
          <div
            className="image-container bg-grey cursor-pointer"
            onClick={() => showPreview(0)}
          >
            <div className='flex justify-between actions'>
              <Button>
                {index + 1}
              </Button>

              <Space>
                <Button
                  className="square"
                  icon={<SearchOutlined />}
                  onClick={(e) => openImagePicker(e, index)}
                />
                <Button
                  className="square"
                  icon={<Icon component={DeleteIcon} />}
                />
              </Space>
            </div>
            <Image src={media.url} preview={false} />
          </div>
          <Collapse ghost>
            <Collapse.Panel header="Métadata" key="1">
              <TextInput
                handleUpdate={(data) => updateMediaText(data, index)}
                data={media.alt}
                name={"alt"}
                label={"Balise alternative"}
                placeholder="Saisissez du texte"
              />
              <TextInput
                handleUpdate={(data) => updateMediaText(data, index)}
                data={media.legend}
                name={"legend"}
                label={"Légende"}
                placeholder="Saisissez du texte"
              />
              <TextInput
                noLng
                handleUpdate={(data) => updateMediaText(data, index)}
                data={media.credit}
                name={"credit"}
                label={"Crédit"}
                placeholder="Saisissez du texte"
              />
            </Collapse.Panel>
          </Collapse>
        </div>
      ))}
      {localMedias.length < 3 && (
        <div key={"new-media"}>
          <div
            className="image-container bg-grey cursor-pointer flex align-center justify-center"
            onClick={(e) => openImagePicker(e, localMedias.length)}
          >
            <p>Cliquez ici pour choisir un média</p>
            <div className='flex justify-between actions'>
              <Button >
                {localMedias.length + 1}
              </Button>
              <Button
                className="square"
                icon={<SearchOutlined />}
                onClick={(e) => openImagePicker(e, localMedias.length)}
              />
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{
            visible: previewVisible,
            onVisibleChange: handleVisibleChange,
            current: currentImg,
          }}
        >
          {localMedias.map((media, index) => (
            <Image src={media.url} key={`preview_${index}`} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
}
