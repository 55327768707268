import React, { useEffect, useState } from 'react'
import { Button, Slider, Space } from 'antd'
import InputColor from '../../../InputColor'
import ExternalIcon from './ExternalIcon'
import AnchorSettings from './AnchorSettings'

export default function EditIconLayer({ layer, setLayer, deleteLayer, isPro }) {

    const [localLayer, setLocalLayer] = useState(null)

    useEffect(() => {
        if (JSON.stringify(layer) !== JSON.stringify(localLayer)) {
            setLocalLayer(layer)
        }
    }, [layer])

    const handleChange = (property, value) => {
        //console.log(value)
        let newLayer = { ...localLayer, [property]: value }
        setLocalLayer(newLayer)
        setLayer(newLayer)
    }

    const handleStrokeValue = (property, value) => {
        handleChange('stroke', { ...layer.stroke, [property]: value })
    }

    const insertIcon = (src) => {

        let newLayer = {
            "type": "icon",
            "src": src,
            "fill": "#000",
            "opacity": 1,
            "ratio": 0.3,
            "anchor": [0.5, 0.5],
            "stroke": { "color": "#fff", "width": 0 }
        };
        //console.log('tt', newLayer)
        setLocalLayer(newLayer)
        setLayer(newLayer)
    }

    const handleDelete = () => {
        deleteLayer(localLayer.id)
        setLocalLayer(null)
    }



    return (
        <div>
            {
                !localLayer ?
                    <div>
                        <ExternalIcon handleSelect={insertIcon} />
                    </div> :
                    <Space direction='vertical' style={{ width: '100%' }}>
                        <ExternalIcon title="Modifier l'icone" handleSelect={src => handleChange('src', src)} current={localLayer.src}/>
                        <div>
                            <div>Taille de l'icone :</div>
                            <Slider
                                value={localLayer?.ratio * 100}
                                onChange={value => handleChange('ratio', value / 100)}
                                max={200}
                            />
                        </div>
                        <div>
                            <div>Couleur du visuel :</div>
                            <InputColor
                                value={localLayer?.fill}
                                onChange={(value) => handleChange('fill', value)}
                            />
                        </div>
                        <div>
                            <div>Epaisseur de la bordure :</div>
                            <Slider
                                value={localLayer?.stroke?.width}
                                onChange={value => handleStrokeValue('width', value)}
                                max={30}
                            />
                        </div>
                        <div>
                            <div>Couleur de la bordure :</div>
                            <InputColor
                                value={localLayer?.stroke?.color}
                                onChange={value => handleStrokeValue('color', value)}
                            />
                        </div>

                        <div>
                            <div>Transparence :</div>
                            <Slider
                                value={localLayer?.opacity * 100}
                                onChange={value => handleChange('opacity', value / 100)}
                                max={100}
                            />
                        </div>

                        {
                            isPro &&
                            <AnchorSettings anchor={localLayer?.anchor} handleUpdate={(value) => handleChange('anchor', value)} />
                        }
                        <Button danger size="small" onClick={handleDelete} >Supprimer cet icone</Button>

                    </Space>

            }
        </div>
    )
}
