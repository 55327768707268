import React, { useEffect, useState } from 'react'
import { Space } from 'antd';
import { useEditorContext } from '../../../providers/EditorProvider'

import FeatureItem from './FeatureItem';

import { ItemTypes } from './ItemTypes';
import { useDrop } from 'react-dnd';

export default function FeatureList({ selectedFeature, setSelectedFeature }) {

  const [editorState, editorDispatch] = useEditorContext()
  const { updateFeatureOnDrag } = editorDispatch;
  const [localFeatures, setLocalFeatures] = useState([])

  useEffect(() => {
    setLocalFeatures([...editorState.features])
  }, [editorState.features])

  function handleSelect(feature) {
    setSelectedFeature(prev => prev?.id === feature?.id ? null : feature)
  }


  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.FEATURE,
    drop: (props) => updateFeatureOnDrag(null, props),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  if (!localFeatures.length)
    return (
      <div className='light' style={{textAlign: 'center'}}>
        Rechercher un lieu pour ajouter un marqueur
      </div>
    )

  return (
    <div>
      <Space direction='vertical' style={{ width: '100%', paddingBottom: '1em' }} >
        {
          localFeatures.map((feature, index) =>
            <FeatureItem key={feature.id} feature={feature} handleSelect={handleSelect} selectedFeature={selectedFeature} index={index} updateFeatureOnDrag={updateFeatureOnDrag} isLast={index === localFeatures.length - 1}/>
          )
        }
        {
          canDrop &&
          <div ref={drop} style={{ margin: '.5em auto', width: '90%', padding: '1em', border: '2px dashed #2494d1', borderRadius: '.5em' }}>Déposez ici</div>

        }
      </Space>
    </div>
  )
}
