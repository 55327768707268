import { Collapse } from 'antd'
import React, { useEffect, useState } from 'react'
import PanelDisplayCol from './PanelDisplayCol'

export default function PanelContentCarousel({ panel, updatePanel, getFeatureName }) {

    const [root, setRoot] = useState(null)
    const [firstChild, setFirstChild] = useState(null)
    const [secondChild, setSecondChild] = useState(null)
    const [needUpdate, setNeedUpdate] = useState(0)

    useEffect(() => {
        //console.log(panel)
        if (JSON.stringify(root) !== JSON.stringify(panel)) {
            setRoot(panel)
            let first = panel?.childs
            let second = first?.childs
            console.log(first)
            setFirstChild(first)
            setSecondChild(second)
        }
    }, [panel])

    useEffect(() => {
        if (needUpdate)
            updatePanel(root)
    }, [needUpdate])

    function handleUpdate(data, level) {
        let second = level === 2 ? { ...secondChild, ...data } : secondChild
        let first = level === 1 ? { ...firstChild, ...data, childs: second } : { ...firstChild, childs: second }
        let base = level === 0 ? { ...root, ...data, childs: first } : { ...root, childs: first }

        setSecondChild(second)
        setFirstChild(first)
        setRoot(base)
        setNeedUpdate((new Date).getTime())
    }

    return (
        <>
            <div className='light pd-default'>Sélectionner ce qui doit s'afficher dans le panneau.</div>
            <Collapse ghost expandIconPosition="right" defaultActiveKey={['source']} accordion>
                {
                    root &&
                    <Collapse.Panel header={
                        <div>Dossier {panel.src ? <span style={{ color: '#2494D1', fontWeight: 'bold' }}>{getFeatureName(panel.src)}</span> : 'source'} </div>
                    } key='source'>
                        <PanelDisplayCol element={root} handleUpdate={handleUpdate} visible={true} level={0} />
                    </Collapse.Panel>
                }
                {
                    firstChild &&
                    <Collapse.Panel header={
                        <div>Pour les enfants direct de votre dossier {panel.src ? <span style={{ color: '#2494D1', fontWeight: 'bold' }}>{getFeatureName(panel.src)}</span> : 'source'}</div>
                    } key='firstChild'>
                        <PanelDisplayCol element={firstChild} handleUpdate={handleUpdate} visible={true} level={1} />
                    </Collapse.Panel>
                }
                {
                    secondChild &&
                    <Collapse.Panel header={
                        <div>Pour les enfants suivants</div>
                    } key='secondChild'>
                        <PanelDisplayCol element={secondChild} handleUpdate={handleUpdate} visible={true} level={2} />
                    </Collapse.Panel>
                }

            </Collapse>
        </>
    )
}
