import React, { useEffect, useState } from "react";
import { useEditorContext } from "../../../providers/EditorProvider";
import { Button } from "antd";
import SimpleSettings from "./marker/SimpleSettings";
import ProSettings from "./marker/ProSettings";
import ModeSelect from "./ModeSelect";
import MarkerSettings from "./marker/MarkerSettings";
import { PlusOutlined } from "@ant-design/icons";
import { getPoint, baseSettings } from "../helpers";
import MiniMap from "../MiniMap";

export default function MarkerEdit({
  feature,
  handleUpdate,
  property = "marker",
  menu = null,
}) {
  const [editorState, editorDispatch] = useEditorContext();
  const { deepCopy, updateFeature, deleteStyle, updateStyle } = editorDispatch;

  const [editedMarker, setEditedMarker] = useState(null);
  const [mode, setMode] = useState("default");
  const [hideMenu, setHideMenu] = useState(false);

  useEffect(() => {
    let styleId = null;
    if (feature) {
      styleId =
        feature.type === "marker" ? feature.style : feature.style[property];
    }

    let currentStyle = editorState?.styles.marker.find((item) => item.id === styleId)
      || editorState.defaults.styles.marker;
    setEditedMarker(deepCopy(currentStyle));

  }, [feature, editorState.reloadMap]);

  function setMarker(value) {
    if (feature && !editedMarker.id) {
      editedMarker.id = "marker_" + new Date().getTime();
      editedMarker.name = feature.label.fr;
      let style =
        feature.type === "marker"
          ? editedMarker.id
          : { ...feature.style, [property]: editedMarker.id };

      handleUpdate({ ...feature, style: style }, false);
    }

    editedMarker[mode] = value;
    setEditedMarker({ ...editedMarker });
    updateStyle("marker", { ...editedMarker }, !feature);
  }

  function clear() {
    let style =
      feature.type === "marker" ? null : { ...feature.style, [property]: null };

    deleteStyle("marker", editedMarker.id);
    setEditedMarker(deepCopy(editorState.defaults.styles.marker));
    updateFeature({ ...feature, style: style }, true);
  }

  function addMode() {
    let newMode = deepCopy(editedMarker.default);
    newMode.layers = newMode?.layers.map((item, index) => {
      return { ...item, id: new Date().getTime() + "-" + index };
    });
    editedMarker[mode] = newMode;

    setEditedMarker({ ...editedMarker });
  }

  const handleMarkerEdit = (property, value) => {
    setMarker({ ...editedMarker[mode], [property]: value });
  };


  function handleDeleteMode() {
    setMarker(undefined);
    setMode('default');
  }

  return (
    <div>
      {!!menu && !hideMenu && menu()}
      {editedMarker && (
        <>
          <MiniMap
            settings={{
              ...baseSettings,
              features: [getPoint(false, false)],
              defaults: { styles: { marker: { default: editedMarker[mode] } } },
            }}
            mapId={"clusterPreview"}
          />
          {!hideMenu && (
            <ModeSelect
              advancedMode={mode}
              setAdvancedMode={setMode}
            />
          )}
          {editedMarker[mode] ? (
            <>
              {!hideMenu && (
                <MarkerSettings
                  marker={editedMarker[mode]}
                  handleEdit={handleMarkerEdit}
                  isPro={editorState.isProUser}
                />
              )}
              {editorState.isProUser ? (
                <ProSettings
                  setHideMenu={setHideMenu}
                  marker={deepCopy(editedMarker[mode])}
                  setMarker={setMarker}
                />
              ) : (
                <SimpleSettings
                  marker={deepCopy(editedMarker[mode])}
                  setMarker={setMarker}
                />
              )}
              {editedMarker.id ? <Button onClick={clear}>Clear</Button> : null}
            </>
          ) : (
            <div className="mt-10 text-center">
              <Button
                className="mt-10"
                icon={<PlusOutlined />}
                onClick={addMode}
              >
                Personnaliser le style au {mode}
              </Button>
            </div>
          )}
          {
            mode !== 'default' && editedMarker[mode] &&
            <div className="text-center pd-default">
              <Button onClick={handleDeleteMode} type="danger">Supprimer ce style</Button>
            </div>
          }
        </>
      )}
    </div>
  );
}
