import React, {useEffect, useState} from 'react';
import {Slider, Space} from "antd";
import AdvancedLayerSetting from "./AdvancedLayerSetting";

const AdvancedSetting = ({layers, setLayer, marker, setMarker}) => {

    const [shapeLayer, setShapeLayer] = useState(null)
    const [iconLayer, setIconLayer] = useState(null)
    const [textLayer, setTextLayer] = useState(null)

    useEffect(() => {
        const _shapeLayer = layers.find(item => item.type === 'symbole' || item.type === 'shape')
        if (_shapeLayer?.id !== shapeLayer?.id) {
            setShapeLayer(_shapeLayer)
        }

        const _iconLayer = layers.find(item => item.type === 'icon')
        if (_iconLayer?.id !== iconLayer?.id) {
            setIconLayer(_iconLayer)
        }

        const _textLayer = layers.find(item => item.type === 'text')
        if (_textLayer?.id !== textLayer?.id) {
            setTextLayer(_textLayer)
        }
    }, [layers])

    const handleShapeChange = (property, value) => {
        let newLayer = {...shapeLayer, [property]: value}
        setShapeLayer(newLayer)
        setLayer(newLayer)
    }

    const handleIconChange = (property, value) => {
        let newLayer = {...iconLayer, [property]: value}
        setIconLayer(newLayer)
        setLayer(newLayer)
    }

    const handleTextChange = (property, value) => {
        let newLayer = {...textLayer, [property]: value}
        setTextLayer(newLayer)
        setLayer(newLayer)
    }

    function updateMarker(key, value) {
        marker[key] = value;
        setMarker({...marker});
    }

    function updateAnchorMarker(axe, value) {
        let _copy = marker?.anchor ? [...marker?.anchor] : [0.5,0.5];

        if (axe === 'x')
            _copy[0] = value / 100;
        else if (axe === 'y')
            _copy[1] = value / 100;

        updateMarker('anchor', _copy);
    }

    return (
        <Space direction="vertical" style={{width: '100%'}}>
            <div>
                <div>Point d'ancrage Principal</div>
                <div>x</div>
                <Slider
                    min={0} max={100}
                    value={(marker?.anchor ? marker?.anchor[0] : 0.5) * 100}
                    onChange={value => updateAnchorMarker('x', value)}
                />
                <div>y</div>
                <Slider
                    min={0} max={100}
                    value={(marker?.anchor ? marker?.anchor[1] : 0.5) * 100}
                    onChange={value => updateAnchorMarker('y', value)}
                />
            </div>

            {shapeLayer &&
                <AdvancedLayerSetting layer={shapeLayer} setLayer={handleShapeChange} type="forme"/>}

            {iconLayer &&
                <AdvancedLayerSetting layer={iconLayer} setLayer={handleIconChange} type="icone"/>}

            {textLayer &&
                <AdvancedLayerSetting layer={textLayer} setLayer={handleTextChange} type="texte"/>}
        </Space>
    );
};

export default AdvancedSetting;