import React from "react";
import {Slider} from "antd";

export default function SizeSettings({marker, updateMarker}) {

    return (
        <div className="mv-1em">
            <div>Taille du marqueur</div>
            <Slider
                value={marker?.size}
                onChange={value => updateMarker('size', value)}
                min={20}
            />
        </div>
    )
}