import React, { useEffect, useState } from 'react'
import { Switch, Select } from 'antd'
import Icon from '@ant-design/icons'
import { ReactComponent as IconPannel } from '../../../assets/pannel.svg'


export default function PanelDisplayCol({ element, handleUpdate, visible, level = 0 }) {

    const [column, setColumn] = useState(element?.column || null)
    const [display, setDisplay] = useState(element.display || null)
    const [needUpdate, setNeedUpdate] = useState(0)

    useEffect(() => {
        setColumn(element?.column || null)
        setDisplay(element.display || null)
    }, [element])

    useEffect(() => {
        //console.log('update', level, display)
        if (needUpdate) {
            handleUpdate({ ...element, display, column}, level)
        }
    }, [needUpdate])

    function updatedisplay(property, value) {
        setDisplay(prev => { return { ...prev, [property]: value } })
        setNeedUpdate((new Date()).getTime())
    }

    function updateCol(value, type) {
        setColumn(prev => { return { ...prev, [type]: value } })
        setNeedUpdate((new Date()).getTime())
    }

    return (
        <div>
            <div>
                <div>
                    <div className='flex justify-between'><span>Afficher le label       </span>   <Switch onChange={checked => updatedisplay('label', checked)} size="small" checked={display?.label} />       </div>
                    <div className='flex justify-between'><span>Afficher la baseline </span>      <Switch onChange={checked => updatedisplay('baseline', checked)} size="small" checked={display?.baseline} /> </div>
                    <div className='flex justify-between'><span>Afficher l'introduction </span>   <Switch onChange={checked => updatedisplay('intro', checked)} size="small" checked={display?.intro} />       </div>
                    <div className='flex justify-between'><span>Afficher le texte        </span>  <Switch onChange={checked => updatedisplay('text', checked)} size="small" checked={display?.text} />         </div>
                    <div className='flex justify-between'><span>Afficher les medias     </span>   <Switch onChange={checked => updatedisplay('media', checked)} size="small" checked={display?.media} />       </div>
                    <div className='flex justify-between'><span>Afficher les enfants   </span>    <Switch onChange={checked => updatedisplay('childs', checked)} size="small" checked={display?.childs} />     </div>
                </div>
            </div>
            {
                column &&
                <div className="column-number">
                    <p className="mv-1em"><Icon component={IconPannel} /> Nombre de colonnes</p>
                    <div className="mb-small flex justify-between">
                        <span>Pc :</span>
                            <Select value={column.desktop} onChange={(value) => updateCol(value, 'desktop')} size="small">
                                <Select.Option value={1}>1</Select.Option>
                                <Select.Option value={2}>2</Select.Option>
                                <Select.Option value={3}>3</Select.Option>
                                <Select.Option value={4}>4</Select.Option>
                            </Select>
                    </div>
                    <div className="mb-small flex justify-between">
                        <span>Tablette : </span>
                            <Select value={column.tablet} onChange={(value) => updateCol(value, 'tablet')} size="small">
                                <Select.Option value={1}>1</Select.Option>
                                <Select.Option value={2}>2</Select.Option>
                                <Select.Option value={3}>3</Select.Option>
                                <Select.Option value={4}>4</Select.Option>
                            </Select>
                    </div>
                    <div className="mb-small flex justify-between">
                        <span >Mobile : </span>
                            <Select value={column.mobile} onChange={(value) => updateCol(value, 'mobile')} size="small">
                                <Select.Option value={1}>1</Select.Option>
                                <Select.Option value={2}>2</Select.Option>
                            </Select>
                    </div>
                </div>
            }
        </div>
    )
}
