import React, { useEffect, useState } from 'react'
import { Form, Input, Radio, Switch } from 'antd'
import { useEditorContext } from '../../providers/EditorProvider';

export default function LinkInput({ data, label, handleUpdate }) {

	const [editorState, editorDispatch] = useEditorContext()

	const avalaibleLng = editorState.languages || [
        { label: 'fr', value: 'fr' },
        { label: 'en', value: 'en' },
    ];
    const defaultLanguage = editorState.defaultLanguage || 'fr';
    const defaultContent = {}
    defaultContent[defaultLanguage] = '';

    const [linkLabel,setLinkLabel] = useState(data?.label || defaultContent)
    const [linkUrl,setLinkUrl] = useState(data?.url || defaultContent)
    const [external,setExternal] = useState(data?.external || false)
    const [lng, setLng] = useState(defaultLanguage)

    useEffect(() => {
        setLinkLabel(data?.label || defaultContent)
        setLinkUrl(data?.url || defaultContent)
        setExternal(data?.external || false)
    }, [data])

    function handleChange(e, setter) {
        //console.log(e.target.value)
        setter(prev => {return {...prev, [lng]: e.target.value}})
    }

    function handleLngChange(e) {
        setLng(e.target.value)
    }

    function handleSwitch(checked) {
        setExternal(checked)
    }

    const lngButtons = (
        <Radio.Group
            size="small"
            options={avalaibleLng}
            onChange={handleLngChange}
            value={lng}
            optionType="button"
            buttonStyle="solid"
        />
    )

    function handleBlur() {
        let link = {
            label: linkLabel,
            url: linkUrl,
            external: external
        }
        //console.log(link)
        handleUpdate({link: link})

    }

    return (
        <Form.Item>
            <div className="flex justify-between align-baseline"><div>{label}</div> <div>{lngButtons}</div></div>
            <Input value={linkLabel[lng]} onChange={(e) => handleChange(e, setLinkLabel)} onBlur={handleBlur} style={{marginBottom: '1em'}} placeholder="Saisissez le libellé du lien additionnel"/>
            <Input value={linkUrl[lng]} onChange={(e) => handleChange(e, setLinkUrl)} onBlur={handleBlur} placeholder="Saisissez l’URL du lien additionnel"/>
            <p>Ouvrir dans une nouvelle fenetre ? <Switch checked={external} onChange={handleSwitch} onBlur={handleBlur} /></p>
        </Form.Item>
    )
}
