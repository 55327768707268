import React, { useEffect, useState } from 'react'
import { Space, Avatar, Dropdown, Button, Menu } from 'antd';
import Icon, { ArrowLeftOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import EditIconLayer from './EditIconLayer';
import EditShapeLayer from './EditShapeLayer';
import EditTextLayer from './EditTextLayer';
import { ReactComponent as DeleteIcon } from '../../../../assets/trash.svg'


export default function ProSettings({ marker, setMarker, setHideMenu }) {

    const [layers, setLayers] = useState([]);
    const [selectedLayer, setSelectedLayer] = useState(null)

    useEffect(() => {
        //console.log(marker)
        if (marker?.layers) {
            setLayers([...marker.layers]);
        } else {
            setLayers([])
        }
    }, [marker])

    const handleLayerEdit = (newLayer) => {
        let newLayers;
        if (newLayer?.id) {
            newLayers = layers.map(layer => layer.id === newLayer.id ? newLayer : layer)
        } else {
            newLayer.id = 'layer_' + (new Date()).getTime()
            newLayers = [...layers, newLayer]
        }
        //console.log(newLayers)
        setSelectedLayer(newLayer)
        setMarker({ ...marker, layers: newLayers })
    }


    const deleteLayer = (id, e = null) => {
        if (e)
            e.stopPropagation()
        let newLayers = layers.filter(layer => layer.id !== id)
        setMarker({ ...marker, layers: newLayers })
    }

    const selectLayer = (item) => {
        //console.log(item)
        setSelectedLayer(item)
        setHideMenu(!!item)
    }

    function handleMenuClick(e) {
        //console.log('click', e);
        selectLayer({ type: e.key })
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="shape">
                Une forme
            </Menu.Item>
            <Menu.Item key="icon">
                Un icone
            </Menu.Item>
            <Menu.Item key="text">
                Du texte
            </Menu.Item>
        </Menu>
    );

    if (selectedLayer) {
        return (
            <div>
                <div className='top-menu-item'>
                    <div>
                        <Avatar
                            onClick={() => selectLayer(null)}
                            className='cursor-pointer'
                            style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                            icon={<ArrowLeftOutlined />} />
                        <span>Modifier la couche {selectedLayer.type}</span>
                    </div>
                </div>
                <div className='pd-default'>
                    {
                        selectedLayer.type === 'icon' &&
                        <EditIconLayer isPro={true} layer={selectedLayer} setLayer={handleLayerEdit} deleteLayer={deleteLayer} />
                    }
                    {
                        selectedLayer.type === 'text' &&
                        <EditTextLayer layer={selectedLayer} setLayer={handleLayerEdit} deleteLayer={deleteLayer} />
                    }
                    {
                        (selectedLayer.type === 'shape' || selectedLayer.type === 'symbole') &&
                        <EditShapeLayer isPro={true} layer={selectedLayer} setLayer={handleLayerEdit} deleteLayer={deleteLayer} />
                    }
                </div>
            </div>
        )
    }

    return (
        <div>
            {
                marker &&
                <>
                    <p className="pd-default light" style={{textAlign: 'center'}}>Vous pouvez superposer plusieurs couches pour créer un marqueur. Sélectionné une couche pour la modifier.</p>
                    {
                        layers.map((item) => (
                            <div className='style-menu-item' onClick={() => selectLayer(item)} key={item.id}>
                                <div>Couche : {item.type} </div>
                                <Space>
                                    <Icon style={{ color: "#E64D3D" }} component={DeleteIcon} onClick={(e) => deleteLayer(item.id, e)} title="Supprimer cette couche" />
                                    <RightOutlined />
                                </Space>
                            </div>
                        ))
                    }
                    <div style={{textAlign: 'center', marginTop: '1em'}}>
                        <Dropdown overlay={menu}>
                            <Button>
                                Ajouter une couche <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>
                </>
            }
        </div>
    )
}
