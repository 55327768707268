import React, { useState } from 'react'
import Icon, { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons'
import PathEdit from '../styles/PathEdit';
import { ReactComponent as IconMarker } from '../../../assets/marker.svg'
import { ReactComponent as IconPath } from '../../../assets/path.svg'
import { Avatar } from 'antd';
import MarkerEdit from '../styles/MarkerEdit';

const nav = [
    { id: 'path', title: "Itinéraires", icon: { default: IconPath } },
    { id: 'marker', title: "Marqueurs", icon: { default: IconMarker } },
    { id: 'waypoint', title: "Points de passage", icon: { default: IconMarker } },
];

export default function FeaturePathStyle({ feature, handleUpdate }) {

    const [currentStyle, setCurrentStyle] = useState(null)

    if (currentStyle) {
        return (
            <>
                <div className='top-menu-item'>
                    <div>
                        <Avatar
                            onClick={() => setCurrentStyle(null)}
                            className='cursor-pointer'
                            style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                            icon={<ArrowLeftOutlined />} />
                        <span style={{ marginRight: '1em' }}><Icon style={{ color: '#2494d1' }} component={currentStyle.icon.default} /></span>
                        <span>{currentStyle.title}</span>
                    </div>
                </div>
                {
                    currentStyle.id === 'path' &&
                    <PathEdit feature={feature} handleUpdate={handleUpdate} />
                }
                {
                    currentStyle.id.match(/marker|waypoint/) &&
                    <MarkerEdit feature={feature} handleUpdate={handleUpdate} property={currentStyle.id} />
                }
            </>
        )
    }

    return (
        <div>
            <p className="pd-default light" style={{ textAlign: 'center' }}>Un itinéraire est composé de marqueur, de point de passage ainsi que du chemin à suivre, vous pouvez ici modifier leur style.</p>
            <div>
                {
                    nav.map(item =>
                        <div className='style-menu-item' onClick={() => setCurrentStyle(item)} key={item.id}>
                            <div>
                                <Avatar style={{ backgroundColor: '#2494d1', marginRight: '1em' }} icon={<Icon style={{ color: 'white' }} component={item.icon.default} />} />
                                <span>{item.title}</span>
                            </div>
                            <RightOutlined />
                        </div>
                    )
                }
            </div>
        </div>
    )
}
