import React from 'react'
import {Layout, Menu} from 'antd';
import {DesktopOutlined, FileOutlined, PieChartOutlined, TeamOutlined, UserOutlined} from "@ant-design/icons";
import {useAuthContext} from '../providers/AuthProvider.js';

const {Content, Sider} = Layout;

export default function MainLayout({children, ...rest}) {

    const [authState, authDispatch] = useAuthContext();
    const {authIsLogged} = authDispatch

    return (
        <Layout className="mainLayout">
            {authIsLogged() ?? <Sider collapsible className="site-layout-background">
                <div className="logo"/>
                <Menu defaultSelectedKeys={['1']} mode="inline">
                    <Menu.Item key="1" icon={<PieChartOutlined/>}>
                        Option 1
                    </Menu.Item>
                    <Menu.Item key="2" icon={<DesktopOutlined/>}>
                        Option 2
                    </Menu.Item>
                    <Menu.SubMenu key="sub1" icon={<UserOutlined/>} title="User">
                        <Menu.Item key="3">Tom</Menu.Item>
                        <Menu.Item key="4">Bill</Menu.Item>
                        <Menu.Item key="5">Alex</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu key="sub2" icon={<TeamOutlined/>} title="Team">
                        <Menu.Item key="6">Team 1</Menu.Item>
                        <Menu.Item key="8">Team 2</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key="9" icon={<FileOutlined/>}>
                        Files
                    </Menu.Item>
                </Menu>
            </Sider>}
            <Content className="flex" style={{padding: 50}}>
                <Layout className="site-layout-background" style={{maxWidth: '100%'}}>
                    <Content style={{padding: 24, minHeight: 280}}>
                        {children}
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )

}