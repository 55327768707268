import { Slider, Tag } from 'antd'
import React, { useEffect, useState } from 'react'

export default function AnchorSettings({ anchor, handleUpdate }) {

    const [internalValue, setInternalValue] = useState([0.5, 0.5])

    useEffect(() => {
        if (anchor && anchor !== internalValue) {
            setInternalValue(anchor)
        }
    }, [anchor])


    function updateValue(axe, value) {
        console.log('internalValue', internalValue)
        let y = axe === 'y' ? value / 100 : internalValue[0] 
        let x = axe === 'x' ? value / 100 : internalValue[1]
console.log('first', [y, x])
        setInternalValue([y, x])
        handleUpdate([y, x]);
    }

    function onChange(checked) {
        handleUpdate(checked ? null : internalValue)
    }

    return (
        <div>
            <div className="flex justify-between mb-default">
                <span className='bold'>Position</span>
                <Tag.CheckableTag
                    style={anchor !== null ? {backgroundColor: '#f4f4f4'} : {}}
                    checked={anchor === null}
                    onChange={onChange}
                >
                    Auto
                </Tag.CheckableTag>
            </div>
            <div style={{ opacity: anchor === null ? '.7' : 1 }}>
                <div>Vertical</div>
                <Slider
                    min={0} max={100}
                    value={internalValue[1] * 100}
                    onChange={value => updateValue('x', value)}
                />
                <div>Horizontal</div>
                <Slider
                    min={0} max={100}
                    value={internalValue[0] * 100}
                    onChange={value => updateValue('y', value)}
                />
            </div>
        </div>
    )
}
