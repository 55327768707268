import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Layout from './Layout.js';

const PublicRoute = ({component: Component, layout, ...rest}) => {

  const renderer = (layout,props) => { return <Layout visitor={rest.visitor} layout={layout} private={false} {...props}><Component {...props} /></Layout> }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={renderer.bind(null,layout)} />
  );
};

export default PublicRoute;
