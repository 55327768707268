import React, { useState } from "react";
import { Button, Space } from "antd";
import { RightOutlined } from "@ant-design/icons";
import ClusterMarker from "./ClusterMarker";

export default function LevelCluster({ element, setElement, menu = null }) {

    const [level, setLevel] = useState(null);

    function edit(level = 0) {
        setLevel(level);
    }

    return (
        <div>
            {level !== null ?
                <ClusterMarker
                    level={level} setLevel={setLevel}
                    editedCluster={element} setEditedCluster={setElement}
                /> :
                <div>
                    {!!menu && menu()}
                    <div className="pd-default">
                        <p className="light">Les dossiers permettent de regrouper les marqueurs qui sont situés à
                            proximité lorsque l'utilisateur réduit le zoom de la carte.</p>
                    </div>
                    <div>
                        {!element.collection || !element.collection.length ?
                            <div><p className="light"><em>Pas encore de règle défini pour ce cluster
                                ...</em>
                            </p></div>
                            :
                            element.collection.map((c, index) => (
                                <div className='style-menu-item' onClick={() => edit(index)} key={index}>
                                    <div> Groupe de <span style={{color: '#2494D1', fontWeight: 'bold'}}>{c.from}</span> marqueurs</div>
                                    <Space>
                                        {/* <Icon style={{ color: "#E64D3D" }} component={DeleteIcon} onClick={(e) => handleDelete(e, panel.id, type)} title="Supprimer ce panneau" /> */}
                                        <RightOutlined />
                                    </Space>
                                </div>
                            ))}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '1em'}}>
                        <Button onClick={() => edit(element.collection.length)}>
                            Ajouter un groupe
                        </Button>
                    </div>

                </div>}
        </div>
    )
}