import React from 'react'
import { Layout } from 'antd';

const { Content } = Layout;

export default function EditorLayout({ children }) {

    return (
        <Layout className="site-layout-background" style={{ maxWidth: '100%' }}>
            {children}
        </Layout>
    )

}