import React, { useEffect, useState } from 'react'
import { Form, Input, Radio } from 'antd'
import { Editor } from '@tinymce/tinymce-react';
import { useEditorContext } from '../../providers/EditorProvider';

export default function TextInput({ data, name, label, handleUpdate, placeholder = "", textArea = false, noLng = false }) {

	const [editorState, editorDispatch] = useEditorContext()

	const avalaibleLng = editorState.languages || [
        { label: 'fr', value: 'fr' },
        { label: 'en', value: 'en' },
    ];
    const defaultLanguage = editorState.defaultLanguage || 'fr';
    const defaultContent = {};
    defaultContent[defaultLanguage] = '';

    const [text, setText] = useState(data || defaultContent)
    const [lng, setLng] = useState(defaultLanguage)
    const [needUpdate, setNeedUpdate] = useState(0)
    const [updateTimeout, setUpdateTimeout] = useState(null)

    useEffect(() => {
    }, [editorState])

    useEffect(() => {
        setText(data || defaultContent)
    }, [data])

    useEffect(() => {
        if (needUpdate)
            updateData()
    }, [needUpdate])

    function handleChange(e) {
        setText(prev => { return { ...prev, [lng]: e.target ? e.target.value : e } })
        clearTimeout(updateTimeout)
        setUpdateTimeout(setTimeout(() => {
           setNeedUpdate((new Date()).getTime())
        }, 250))
    }

    function handleLngChange(e) {
        setLng(e.target.value)
    }

    const lngButtons = (
        <Radio.Group
            size="small"
            options={avalaibleLng}
            onChange={handleLngChange}
            value={lng}
            optionType="button"
            buttonStyle="solid"
        />
    )

    const updateData = () => {
        if (noLng) {
            handleUpdate({ [name]: text.fr })
        } else {
            handleUpdate({ [name]: text })
        }
    }

    return (
        <Form.Item>
            <div className="flex justify-between align-baseline" style={{ marginBottom: '1em' }}><div>{label}</div> <div>{!noLng && lngButtons}</div></div>
            {
                textArea ?
                    <Editor
                        apiKey="8aun4gp32xsaajfcsiwh0v25ijdy2dbyruqtxb0c6snaqld4"
                        init={{
                            max_chars: 100,
                            height: 250,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic forecolor backcolor | \
                              alignleft aligncenter alignright alignjustify | \
                              bullist numlist outdent indent | removeformat | help'
                        }}
                        value={text[lng]}
                        onEditorChange={handleChange}
                        onBlur={() => handleUpdate({ [name]: text })}
                        placeholder={placeholder}
                    />
                    :
                    <Input value={text[lng]} onChange={handleChange} placeholder={placeholder} />
            }
        </Form.Item>
    )
}
