import { Space, TreeSelect } from 'antd'
import React from 'react'
import { useEditorContext } from '../../../providers/EditorProvider'
const { TreeNode } = TreeSelect;

export default function FeatureTree({ updateValue, value }) {

    const [editorState, editorDispatch] = useEditorContext()

    function handleSelect(val) {
        //console.log(val)
        updateValue(val)
    }

    function buildTree(features) {
        return features.map(feature =>
            <TreeNode value={feature.id} title={feature.label.fr} key={feature.id}>
                {
                    feature.type === 'layer' && buildTree(feature.features)
                }
            </TreeNode>
        )
    }

    return (
        <Space direction='vertical' style={{width: '100%'}}>
            <div>Source du panneau :</div>
            <TreeSelect style={{ width: '100%' }} onSelect={handleSelect} value={value}>
                {buildTree(editorState.features)}
            </TreeSelect>
        </Space>
    )
}
