import React, { useEffect, useState } from "react";
import { Avatar, InputNumber } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEditorContext } from "../../../../providers/EditorProvider";
import SimpleSettings from "../marker/SimpleSettings";
import ProSettings from "../marker/ProSettings";
import { getCluster, baseSettings } from "../../helpers";
import MiniMap from "../../MiniMap";

const defaultItem = {
  from: 2,
  style: {
    size: 30,
    layers: [
        {
            "id": (new Date()).getTime(),
            "type": "shape",
            "shape": "circle",
            "fill": "hsla(100, 93%, 61%, 0.8)",
            "stroke": {
                "color": "rgba(255,255,255,0.5)",
                "width": 4
            }
        }
    ],
    letter: { color: "#fff", font: "bold 12px arial" },
  },
};

export default function ClusterMarker({
  level,
  setLevel,
  editedCluster,
  setEditedCluster,
}) {
  const [editorState, editorDispatch] = useEditorContext();
  const [collectionItem, setCollectionItem] = useState(defaultItem);
  const [needUpdate, setNeedUpdate] = useState(0);
  const [hideMenu, setHideMenu] = useState(false);

  useEffect(() => {
    setCollectionItem(editedCluster.collection[level] ?? defaultItem);
  }, [level, editedCluster]);

  useEffect(() => {
    if (needUpdate !== 0) {
      editedCluster.collection[level] = collectionItem;
      setEditedCluster({ ...editedCluster });
    }
  }, [needUpdate]);

  function exitEdit() {
    setLevel(null);
  }

  function updateCollection(key, value) {
    setCollectionItem((prev) => {
      return { ...prev, [key]: value };
    });
    setNeedUpdate(new Date().getTime());
  }

  function setMarker(value) {
    //console.log(value)
    setCollectionItem((prev) => {
      return { ...prev, style: value };
    });
    setNeedUpdate(new Date().getTime());
  }


  return (
    <div>
      {!hideMenu && (
        <>
          <div className="top-menu-item">
            <div>
              <Avatar
                onClick={exitEdit}
                className="cursor-pointer"
                style={{ backgroundColor: "#2494d1", marginRight: "1em" }}
                icon={<ArrowLeftOutlined />}
              />
              <span>
                Groupe de "
                <span style={{ color: "#2494D1", fontWeight: "bold" }}>
                  {collectionItem.from}
                </span>
                " marqueurs
              </span>
            </div>
          </div>
            <MiniMap
              settings={{
                ...baseSettings,
                features: getCluster(collectionItem.from),
                defaults: {
                  styles: {
                    cluster: { default: true, collection: [collectionItem] },
                  },
                },
              }}
              mapId={"clusterPreview"}
            />
          <div className="pd-default">
            <div>
              <p className="light">
                Un groupe s'affiche lorsque "
                <span style={{ color: "#2494D1", fontWeight: "bold" }}>
                  {collectionItem.from}
                </span>
                " marqueur(s) d'un même dossier sont trop proche.
              </p>
            </div>
            <div>
              <div className="pb-5">Nombre de marqueur minimum</div>
              <InputNumber
                placeholder={2}
                min={2}
                max={100}
                value={collectionItem?.from}
                onChange={(value) => updateCollection("from", value)}
              />
            </div>
          </div>
        </>
      )}
      {editorState.isProUser ? (
        <ProSettings
          setHideMenu={setHideMenu}
          marker={collectionItem?.style}
          setMarker={setMarker}
        />
      ) : (
        <SimpleSettings marker={collectionItem?.style} setMarker={setMarker} />
      )}
    </div>
  );
}
