/*
=> picker as JS class
1. create instance and set the targeted plateform and a callback function
    - set and HAPI DAM key
    - or set a filemanager base url
2. Open filepicker => start hapidam or RWD file manager based on previous setting
3. On select capture the final image url
4. Execute the main callback function to send back the selected URL
*/

export class Hapi_ImagePicker {
    constructor(param) {
        param = param || {};
        this.settings = {
            connector: param.connector || 'filemanager', // [filemanager|hapidam]
            token: param.token || null,
            endpoint: param.endpoint || null,
            callback: param.callback || null,
        }

        switch (this.settings.connector) {
            case 'filemanager':
                this.connector = new Hapi_ImagePicker_filemanager(this.settings);
                break;
            case 'hapidam':
                this.connector = new Hapi_ImagePicker_hapidam(this.settings);
                break;
        }
    }
    setCallback(callback) {
        this.settings.callback = typeof callback == 'function' ? callback : this.settings.callback
        this.connector.callback = this.settings.callback;
    }
    open() {
        this.connector.callback = this.settings.callback;
        this.connector.open();
    }
    close() {
        this.connector.callback = function () { };
        this.connector.close();
    }
}

class Hapi_ImagePicker_hapidam {
    constructor(param) {
        param = param || {};
        if (typeof param.token == "string") {
            this.client = new Hapidam(param.token);
            if (this.client) {
                this.client.off('select');
                this.client.on('select', function (result) {
                    this.callback({
                        url: result.display.native.original,
                        name: result.name,
                        description: result.description,
                    })
                    this.client.close();
                }.bind(this));
            }
            else {
                throw 'Hapi_ImagePicker_hapidam: and error occured during HapidamClient initialisation';
            }
        }
        else {
            throw 'Hapi_ImagePicker_hapidam: bad auth_token (or missing)';
        }
    }
    open() {
        this.client.open();
    }
    close() {
        this.client.close();
    }
}

class Hapi_ImagePicker_filemanager {
    static bufferInputIndex = 0;
    constructor(param) {
        param = param || {};
        if (typeof param.endpoint == "string") {
            this.idx = Hapi_ImagePicker_filemanager.bufferInputIndex++;

            this.endpoint = new URL(param.endpoint);
            this.endpoint.searchParams.append('akey', 'key_' + this.idx);
            this.endpoint.searchParams.append('type', 2); //[1=image,2=file,3=media]
            this.endpoint.searchParams.append('fldr', '');
            this.endpoint.searchParams.append('popup', 1);
            this.endpoint.searchParams.append('crossdomain', 1);
            this.endpoint.searchParams.append('field_id', 'dummy_' + this.idx);
        }
        else {
            throw "Hapi_ImagePicker_filemanager: bad endpoint string (or missing)";
        }
    }
    _onMessage(event) {
        if (
            this.endpoint.href.toLowerCase().indexOf(event.origin.toLowerCase()) === 0
            && event.data.sender == "responsivefilemanager"
            && event.data.field_id == 'dummy_' + this.idx
        ) {
            var url = new URL(event.data.url);
            this.callback({
                url: url.href,
                name: url.pathname.replace(/^.*\/([^\/]+)\.(jpe?g|png|gif|svg)$/, "$1"),
                description: '',
            })
            this.close();
        }
    }
    open() {
        var W = window.screen.availWidth;
        var H = window.screen.availHeight;
        // close previous instance
        if (this.filemanager)
            this.filemanager.close();
        // add message listener
        window.addEventListener('message', this._onMessage.bind(this), false);
        // open the filemanager
        this.filemanager = window.open(this.endpoint.href, 'rwdfm_popup_' + this.idx, 'width=' + (W * .8) + ', height=' + (H * .8) + ', top=' + (H * .1) + ', left=' + (W * .1) + '');
    }
    close() {
        window.removeEventListener('message', this._onMessage.bind(this), false);
        if (this.filemanager)
            this.filemanager.close();
    }
}