import React, { useEffect, useMemo, useState } from 'react'
import { message } from 'antd'
import { resolveFeatureIcon } from '../helpers';
import { CaretDownOutlined, CaretRightOutlined, DashOutlined } from '@ant-design/icons'
import { ItemTypes } from './ItemTypes';
import { useDrag, useDrop } from 'react-dnd';
import { useEditorContext } from '../../../providers/EditorProvider';

export default function FeatureItem({
    handleSelect,
    selectedFeature,
    feature,
    parent = null,
    updateFeatureOnDrag,
    index,
    deep = 0,
    isLast
}) {

	const [editorState, editorDispatch] = useEditorContext()

    const [isOpen, setIsOpen] = useState(true)
    const [children, setChildren] = useState(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false)
    const [position, setPosition] = useState(null)
    const canDropIn = feature.type === 'layer' || feature.type === 'path'
    const [localFeature, setLocalFeature] = useState(null)
    const [step, setStep] = useState(null)
    

    useEffect(() => {
        if (feature.type === 'layer') {
            setChildren([...feature.features])
        }
        if (feature.type === 'path') {
            setChildren([...feature.steps])
        }
        if (feature.type === 'step') {
            setStep(feature)
            setLocalFeature(feature.marker)
        } else {
            setLocalFeature(feature)
        }

    }, [feature])


    const handleOpen = (e) => {
        e.stopPropagation();
        setIsOpen(prev => !prev);
    }

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.FEATURE,
        item: { ...feature },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [feature, parent]);

    const [, drop] = useDrop(() => ({
        accept: ItemTypes.FEATURE,
        drop: (props) => {

            let dropAfter = position === 'hover-bot'
            setPosition(null)
            setIsDraggingOver(false)
            if (feature.id === props.id) return
            // check if dropped on path feature or one of hes children, and if the type match the feature that can be dropped in path
            const typeRegex = /^marker|step$/
            if ((feature.type === 'path' || (!canDropIn && parent?.type === 'path')) && !props.type.match(typeRegex)) {
                message.error('Uniquement des marqueurs peut être déposé dans un itinéraire')
                return
            }
            if (canDropIn) {
                updateFeatureOnDrag(feature.id, props)
            } else {
                updateFeatureOnDrag(parent?.id, props, {id: feature.id, dropAfter: dropAfter, index: index})
            }

        },
        hover(item, monitor) {
            // on hover
        },
    }), [feature, parent, index, position]);

    const opacity = useMemo(() => isDragging ? 0 : 1, [isDragging]);

    const handleDragStart = () => {
        handleSelect(null)
        setIsOpen(false)
    }

    function onDragOver(e) {
        // console.log(e)
        if (!isDragging) {
            let targetDimensions = e.target.getClientRects()
            let newPosition = e.clientY < targetDimensions[0].top + targetDimensions[0].height / 2 ? 'hover-top' : 'hover-bot';
            //console.log(newPosition)
            setIsDraggingOver(true);
            setPosition(newPosition)

            if (canDropIn && !isOpen)
                setIsOpen(true);

        }
    }

    const caret = useMemo(
        () => isOpen ? <CaretDownOutlined style={{ fontSize: 20 }} /> : <CaretRightOutlined style={{ fontSize: 20 }} />,
        [isOpen]
    )
    const handleClick = () => {
        if (children) {
            setIsOpen(prev => true)
        }
        handleSelect(localFeature)
    }

    return (
        <>
            <div style={{ opacity, paddingLeft: parent && (deep * 25) }}
                title={localFeature?.label?.[editorState.defaultLanguage]}
                onClick={handleClick}
                ref={(node) => drag(drop(node))}
                onDragStart={handleDragStart}
                onDragOver={onDragOver}
                onDragLeave={() => setIsDraggingOver(false)}
                className={`column-line ${selectedFeature?.id === localFeature?.id && 'selected'} 
                ${!canDropIn && isDraggingOver && position} 
                ${isDraggingOver && canDropIn && 'drop-in'}`}>
                <div className='ellipsis'>
                    <span className='mr-default'>{resolveFeatureIcon(localFeature?.type, localFeature)}</span>{localFeature?.label?.[editorState.defaultLanguage]}
                </div>
                <div onClick={handleOpen}>
                    {(localFeature?.type === 'layer' || localFeature?.type === 'path') && caret}
                </div>
            </div>

            {
                step && !isLast &&
                <div style={{ opacity, paddingLeft: parent && (deep * 25) }}
                    title={localFeature?.label?.[editorState.defaultLanguage]}
                    onClick={() => handleSelect(step)}
                    className={`column-line step ${selectedFeature?.id === step.id && 'selected'}`}>
                    <div className='ellipsis'>
                        <span className="mr-default"><DashOutlined style={{ transform: 'rotate(90deg)' }} /></span>
                        <span className='mr-default'>{resolveFeatureIcon(step.type, step)}</span>{step.label.fr}
                    </div>
                </div>
            }

            {
                isOpen && children &&
                children.map((child, index) =>
                    <FeatureItem key={child.id} feature={child} handleSelect={handleSelect}
                        selectedFeature={selectedFeature} parent={feature} index={index}
                        updateFeatureOnDrag={updateFeatureOnDrag} deep={deep + 1}
                        isLast={index === children.length - 1} />
                )
            }
        </>
    )
}
