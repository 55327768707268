import React, { useState } from 'react'
import { Modal, Button, message, Image } from 'antd'
import { useEditorContext } from "../../../providers/EditorProvider";
import Icon from '@ant-design/icons';
import staticPanel from '../../../assets/static-panel.png'
import dynamicPanel from '../../../assets/dynamic-panel.png'
import {ReactComponent as CheckSquare} from '../../../assets/check-square-filled.svg'
import FeatureTree from '../features/FeatureTree';

const defaultDisplay = {
    label: true,
    baseline: true,
    intro: true,
    text: true,
    media: true,
    childs: true,
}


export default function CreatePannel({ visible, setVisible }) {

    const [editorState, editorDispatch] = useEditorContext()
    const { addPanel } = editorDispatch;

    const [type, setType] = useState(null)
    const [source, setSource] = useState(null)

    function createPanel() {
        if (!type) return message.warning('Choississez un type de panneau')

        let newPannel = {
            id: `pan_${(new Date()).getTime()}`,
            src: source,
            display: defaultDisplay,
            childs: {
                column: {
                    mobile: 1,
                    tablet: 1,
                    desktop: 1
                },
                display: defaultDisplay,
                childs: {
                    column: {
                        mobile: 1,
                        tablet: 1,
                        desktop: 1
                    },
                    display: defaultDisplay,
                    childs: {
                        column: {
                            mobile: 1,
                            tablet: 1,
                            desktop: 1
                        },
                        display: defaultDisplay,
                    }
                }
            },
            visible: true
        }
        addPanel(newPannel, type)
        setType(null)
        setSource(null)
        setVisible(false)
    }

    return (
        <Modal visible={visible}
            width='625px' centered
            footer={null}
            onCancel={() => setVisible(false)}>
            <div style={{ padding: '40px 40px 12px' }}>
                <div>
                    <h3>Création d'un nouveau panneau</h3>
                </div>
                <p>Quel type de panneau souhaitez-vous créer ?</p>
                <div className="flex justify-between" style={{ textAlign: 'center'}}>
                    <div onClick={() => setType('static')} className={`button square ${type === 'static' && 'selected'}`} style={{ width: '48%', height: '250px' }}>
                        <Image src={staticPanel} preview={false} style={{marginBottom: '.5em'}}/>
                        <div>
                            <p style={{fontSize: '1em'}}>Un panneau <strong>statique</strong></p>
                            <p className={type === 'static' ? 'selected' : 'light'}>Affichage permanent de vos différents contenus dans une section en dehors de la carte. </p>
                        </div>
                    </div>
                    <div onClick={() => setType('select')} className={`button square ${type === 'select' && 'selected'}`} style={{ width: '48%', height: '250px' }}>
                        <Image src={dynamicPanel} preview={false} style={{marginBottom: '.5em'}}/>
                        <div>
                            <p style={{fontSize: '1em'}}>Un panneau <strong>dynamique</strong></p>
                            <p className={type === 'select' ? 'selected' : 'light'}>
                                Affichage d’un panneau lorsque l’on clique sur un contenu affiché dans la cartographie.
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <FeatureTree value={source} updateValue={setSource} />
                </div>
                <div className="flex justify-center" style={{ padding: '1em 0' }}>
                    <Button onClick={createPanel} size="large" className="success" icon={<Icon component={CheckSquare} />} >Enregistrer</Button>
                </div>
            </div>
        </Modal>
    )
}
