import React from "react";

import PublicRoute from "./PublicRoute";
import Home from "../pages/Home";
import EditorRoute from "./EditorRoute";
import Editor2 from "../pages/Editor2";

export default function Router() {

    return (
        <>
            <PublicRoute exact path="/" component={Home} />
            <EditorRoute exact path="/editor2" component={Editor2} />
        </>
    )
}
