import React, { useCallback, useEffect, useState } from 'react'
import { Button, Layout, Menu, Space } from 'antd'
import Map from '../components/editor/Map';
import FeatureList from '../components/editor/features/FeatureList';
import FeatureEdit from '../components/editor/features/FeatureEdit';
import CreateFeatures from '../components/editor/CreateFeatures';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Styles from '../components/editor/styles/Styles';
import Panels from '../components/editor/tabs/Panels';
import Publish from '../components/editor/tabs/Publish';
import { useEditorContext } from '../providers/EditorProvider';
import { RedoOutlined, SyncOutlined, UndoOutlined } from '@ant-design/icons';

import widget from "../components/WidgetComponent.js"; // client/widget component

const { Header, Content, Sider } = Layout;

let currentMode = 'map'


export default function Editor2() {

    const [editorState, editorDispatch] = useEditorContext()
    const { buildMapJson, handleData, pushToUndo, undo, redo,restartMap } = editorDispatch;

    const [selectedFeature, setSelectedFeature] = useState(null)
    const [current, setCurrent] = useState('map')
    const [saveTimeout, setSaveTimeout] = useState(null)
    const [isDrawing, setIsDrawing] = useState(false)

    useEffect(() => {
        saveState()
    }, [editorState])

    function saveState() {
        clearTimeout(saveTimeout)
        setSaveTimeout(setTimeout(function () {
            pushToUndo()
        }, 500))
    }

    useEffect(() => {
        if(widget.isDefaultsAvailable())
          handleInitialData(widget.getDefaults());
        else
            widget.on('widget.defaults.init',handleInitialData);

        return () => widget.off('widget.defaults.init',handleInitialData);
    }, [])

    const handleInitialData = useCallback(data => {
        data = typeof data === "string" ? JSON.parse(data) : data;
        handleData(data)
    })

    const handleMenu = (e) => {
        setSelectedFeature(null)
        setCurrent(e.key)
        currentMode = e.key
    }

    const handleSelect = (feature) => {
        if (currentMode === 'map') {
            setSelectedFeature(feature)
        }
    }
    const handleSave = () => {
        let fullSettings = buildMapJson();
        // console.log('fullSettings', fullSettings)
        if(window.opener)
        {
            widget.fire('save',fullSettings,'broadcast');
            window.close();
        }
    }

    return (
        <Layout>
            <Header className="header flex justify-between">
                <Menu onClick={handleMenu} mode="horizontal" selectedKeys={[current]} style={{ background: 'transparent', width: '100%' }} theme='dark'>
                    <Menu.Item key="map">Carte</Menu.Item>
                    <Menu.Item key="styles">Styles</Menu.Item>
                    <Menu.Item key="panels">Panneaux</Menu.Item>
                    <Menu.Item key="publish">Publier</Menu.Item>
                </Menu>
                <Space>
                    <Button onClick={restartMap} icon={<SyncOutlined />} title="Recharger la carte" />
                    <Button onClick={undo} disabled={editorState.undoLog.length < 2} icon={<UndoOutlined />} title="Etape précédente" />
                    <Button onClick={redo} disabled={!editorState.redoLog.length} icon={<RedoOutlined />} title="Etape suivante"/>
                    <Button onClick={handleSave}>Enregistrer</Button>
                </Space>
            </Header>
            <Layout>
                <Sider width={300} style={{ background: 'white', boxShadow: '1px 0px 2px -2px black', zIndex: 2, maxHeight: '100%', overflow: 'auto' }}>
                    {
                        current === 'map' &&
                        <div className='pd-default'>
                            <CreateFeatures />
                            <DndProvider backend={HTML5Backend}>
                                <FeatureList selectedFeature={selectedFeature} setSelectedFeature={setSelectedFeature} />
                            </DndProvider>
                        </div>
                    }
                    {
                        current === 'styles' &&
                        <Styles />
                    }
                    {
                        current === 'panels' &&
                        <Panels />
                    }
                    {
                        current === 'publish' &&
                        <Publish isDrawing={isDrawing} setIsDrawing={setIsDrawing}/>
                    }

                </Sider>
                <Layout>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: 0,
                            minHeight: 300,
                            position: 'relative'
                        }}
                    >
                          {
                            selectedFeature &&
                            <div style={{ background: 'white', width: '300px', position: 'absolute', top: 0, bottom: 0, zIndex: 1, transition: 'all .4s', overflow: 'auto'}}>
                                <FeatureEdit feature={selectedFeature} close={() => setSelectedFeature(null)} />
                            </div>
                        }
                        <Map setSelectedFeature={handleSelect} selectedFeature={selectedFeature} view={current} isDrawing={isDrawing} setIsDrawing={setIsDrawing}/>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}
