import { WidgetComponent } from "js-client-widget";
//import { WidgetComponent } from "... my local path to ... \\client-widget-bootstrap\\src\\index.js";


const widget = (function(){
	console.log('new WidgetComponent');
	// si on reçoit un id via l'url on l'exploite (ce sera le cas avec CkEditor)
	// sinon on laisse le client/widget gérer sa communication
	let params = (new URL(document.location)).searchParams;
	let id = params.get('hapidamClientId') || null;
	var widget =  new WidgetComponent({id:id});
	return widget;
})();

export default widget