import React, { useEffect, useState } from 'react'
import { Button, Card, Slider, Space } from 'antd'
import Icon, { CheckCircleOutlined, CheckSquareOutlined, HeatMapOutlined, SafetyCertificateOutlined, SketchOutlined, StarOutlined } from "@ant-design/icons";

import { ReactComponent as Marker1 } from '../../../../assets/preview-marker/marker1.svg'
import { ReactComponent as Marker1Plain } from '../../../../assets/preview-marker/marker1.plain.svg'
import { ReactComponent as Marker2 } from '../../../../assets/preview-marker/marker2.svg'
import { ReactComponent as Marker2Plain } from '../../../../assets/preview-marker/marker2.plain.svg'
import { ReactComponent as Marker3 } from '../../../../assets/preview-marker/marker3.svg'
import { ReactComponent as Marker3Plain } from '../../../../assets/preview-marker/marker3.plain.svg'
import { ReactComponent as Comment } from '../../../../assets/preview-marker/comment.svg'
import { ReactComponent as Comment_plain } from '../../../../assets/preview-marker/comment.plain.svg'
import InputColor from '../../../InputColor';
import AnchorSettings from './AnchorSettings';

const gridStyle = {
    width: '20%',
    textAlign: 'center',
    padding: '6px 8px',
    cursor: 'pointer',
    fontSize: 24
};

const symboles = [
    { name: 'marker1', iconName: Marker1, type: 'symbole' },
    { name: 'marker1_plain', iconName: Marker1Plain, type: 'symbole' },
    { name: 'marker2', iconName: Marker2, type: 'symbole' },
    { name: 'marker2_plain', iconName: Marker2Plain, type: 'symbole' },
    { name: 'marker3', iconName: Marker3, type: 'symbole' },
    { name: 'marker3_plain', iconName: Marker3Plain, type: 'symbole' },
    { name: 'comment', iconName: Comment, type: 'symbole' },
    { name: 'comment_plain', iconName: Comment_plain, type: 'symbole' },
    { name: 'circle', type: 'shape', icon: <CheckCircleOutlined /> },
    { name: 'triangle', type: 'shape', icon: <HeatMapOutlined /> },
    { name: 'square', type: 'shape', icon: <CheckSquareOutlined /> },
    { name: 'diamond', type: 'shape', icon: <SketchOutlined /> },
    { name: 'regular', type: 'shape', icon: <SafetyCertificateOutlined /> },
    { name: 'star', type: 'shape', icon: <StarOutlined /> },
]

const angle = ['regular', 'square', 'diamond', 'triangle', 'star'];
const point = ['regular', 'star'];
const sharpness = ['star'];

const baseLayer = {
    fill: "rgba(74, 144, 226, 1)",
    opacity: 1,
    ratio: 1,
    anchor: null,
    stroke: { "color": "#fff", "width": 1 }
}

export default function EditLayer({ layer, setLayer, deleteLayer, isPro }) {

    const [localLayer, setLocalLayer] = useState(null)
    const [needUpdate, setNeedUpdate] = useState(0)
    const [updateTimeout, setUpdateTimeout] = useState(null)

    useEffect(() => {
        if (JSON.stringify(layer) !== JSON.stringify(localLayer)) {
            setLocalLayer(layer)
        }
    }, [layer])

    useEffect(() => {
        if (needUpdate)
            setLayer(localLayer)
    }, [needUpdate])

    const handleSymbol = (value, type) => {
        let base = localLayer?.id ? { ...localLayer } : { ...baseLayer }
        let newLayer = { ...base, type, shape: value }
        if (type === 'shape') {
            newLayer = { ...newLayer, type, shape: value }
        } else {
            newLayer = { ...base, type, symbole: value }
        }
        setLocalLayer(newLayer)
        setLayer(newLayer)
    }

    const handleChange = (property, value) => {
        //console.log(value)
        let newLayer = { ...localLayer, [property]: value }
        setLocalLayer(newLayer)
        clearTimeout(updateTimeout)
        setUpdateTimeout(setTimeout(() => {
            setNeedUpdate((new Date()).getTime())
        }, 250))
    }

    const handleStrokeValue = (property, value) => {
        handleChange('stroke', { ...layer.stroke, [property]: value })
    }

    const handleDelete = () => {
        deleteLayer(localLayer.id)
        setLocalLayer(null)
    }

    if (!localLayer) {
        return (
            <div>
                <div>Visuel :</div>
                <Card>
                    {
                        symboles.map(item =>
                            <Card.Grid key={item.name} style={{ ...gridStyle, color: localLayer?.fill, }} onClick={() => handleSymbol(item.name, item.type)}>
                                {
                                    item?.iconName ?
                                        <Icon component={item.iconName} />
                                        : item.icon
                                }
                            </Card.Grid>
                        )
                    }
                </Card>
            </div>
        )
    }

    const getIsSelected = (item) => {
        return (layer?.type === item.type && layer?.[item.type] === item.name)
    }

    return (
        <div>
            <Space direction='vertical' style={{ width: '100%' }}>
                <div>Visuel :</div>
                <Card>
                    {
                        symboles.map(item =>
                            <Card.Grid key={item.name}
                                style={{ ...gridStyle, color: '#2494D1', border: `solid 1px ${getIsSelected(item) ? '#2494D1' : 'transparent'}` }}
                                onClick={() => handleSymbol(item.name, item.type)}>
                                {
                                    item?.iconName ?
                                        <Icon component={item.iconName} />
                                        : item.icon
                                }
                            </Card.Grid>
                        )
                    }
                </Card>
                <div>Couleur de l'icone :</div>
                <InputColor
                    value={localLayer?.fill}
                    onChange={(value) => handleChange('fill', value)}
                />

                <div>Epaisseur de la bordure :</div>
                <Slider
                    value={localLayer?.stroke?.width}
                    onChange={value => handleStrokeValue('width', value)}
                    max={30}
                />

                <div>Couleur de la bordure :</div>
                <InputColor
                    value={localLayer?.stroke?.color}
                    onChange={value => handleStrokeValue('color', value)}
                />

                <div>Transparence :</div>
                <Slider
                    value={localLayer?.opacity * 100}
                    onChange={value => handleChange('opacity', value / 100)}
                    max={100}
                />
                {
                    angle.includes(localLayer?.shape) &&
                    <>
                        <div>Inclinaison :</div>
                        <Slider
                            value={localLayer?.angle}
                            onChange={value => handleChange('angle', value)}
                            max={360}
                        />
                    </>
                }
                {
                    point.includes(localLayer?.shape) &&
                    <>
                        <div>Nombre de côté :</div>
                        <Slider
                            value={localLayer?.point || 5}
                            onChange={value => handleChange('point', value)}
                            max={10}
                            min={4}
                        />
                    </>
                }
                {
                    sharpness.includes(localLayer?.shape) &&
                    <>
                        <div>Netteté :</div>
                        <Slider
                            value={localLayer?.sharpness * 100 || 50}
                            onChange={value => handleChange('sharpness', value / 100)}
                            max={100}
                            min={1}
                        />
                    </>
                }
                {
                    isPro &&
                    <AnchorSettings anchor={localLayer?.anchor} handleUpdate={(value) => handleChange('anchor', value)} />
                }

                <Button danger size="small" onClick={handleDelete} >Supprimer cette forme</Button>
            </Space>
        </div>
    )
}
