import React from 'react'
import { Space } from 'antd'
import Icon, { EyeInvisibleOutlined, RightOutlined, EyeOutlined } from "@ant-design/icons";
import { ReactComponent as DeleteIcon } from '../../../assets/trash.svg'
import { ReactComponent as DangerTriangle } from '../../../assets/danger-triangle.svg'

export default function PanelMenuItem({ panel, type, handleDelete, handleVisible, handleSelect, getFeatureName }) {

    return (
        <div className='style-menu-item' onClick={() => handleSelect(panel, type)}>
            <div>
                {
                    panel.src ? getFeatureName(panel.src)
                        : <span className="danger"><Icon component={DangerTriangle} /> Source à définir</span>
                }
            </div>
            <Space>
                {
                    panel.visible ?
                        <EyeOutlined onClick={(e) => handleVisible(e, panel, type, false)} title="Rendre ce panneau invisible" style={{ color: '#2494d1' }} />
                        : <EyeInvisibleOutlined onClick={(e) => handleVisible(e, panel, type, true)} title="Rendre ce panneau visible" />
                }
                <Icon style={{ color: "#E64D3D" }} component={DeleteIcon} onClick={(e) => handleDelete(e, panel.id, type)} title="Supprimer ce panneau" />
                <RightOutlined />
            </Space>
        </div>
    )
}
