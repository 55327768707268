import React, { useEffect, useState } from "react";
import { useEditorContext } from "../../../providers/EditorProvider";
import { Collapse, InputNumber, Slider, Space, Switch } from "antd";
import InputColor from "../../InputColor";
import MiniMap from "../MiniMap";
import { baseSettings, getPoint } from "../helpers";

const { Panel } = Collapse;

export default function PopupEdit({ feature, type = "popup", menu = null }) {

  const [editorState, editorDispatch] = useEditorContext();
  const { updateStyle, deepCopy, updateFeature } = editorDispatch;
  const [style, setStyle] = useState(null);

  useEffect(() => {
    let styleId = null;
    if (feature) {
      styleId = feature[type];
    }

    let currentStyle = styleId
      ? editorState?.styles[type].find((item) => item.id === styleId)
      : editorState.defaults.styles[type];

    setStyle(deepCopy(currentStyle));
  }, [feature, editorState.reloadMap]);

  function handleSave(newStyle) {
    if (feature && !newStyle.id) {
      newStyle.id = type + "_" + new Date().getTime();
      updateFeature({ ...feature, [type]: newStyle.id });
    }
    setStyle(newStyle);
    updateStyle(type, newStyle);
    updateStyle(type, { ...newStyle }, !feature);
  }

  function updateElement(key, value) {
    handleSave({ ...style, [key]: value });
  }

  function handlePadding(value) {
    value = value + "px";
    let paddings = {
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    };
    handleSave({ ...style, ...paddings });
  }

  return (
    <div>
      {!!menu && menu()}
      {style && (
        <MiniMap
          settings={{
            ...baseSettings,
            features: [getPoint(true, true)],
            defaults: {
              styles: {
                [type]: style,
                marker: {
                  default: {
                    size: 60,
                    anchor: [0.5, 1],
                    layers: [
                      {
                        id: "1646929433892-0",
                        type: "symbole",
                        symbole: "marker2_plain",
                        fill: "rgba(74, 144, 226, 1)",
                        opacity: 1,
                        ratio: 1,
                        anchor: [0.5, 0.5],
                        stroke: {
                          color: "#fff",
                          width: 10,
                        },
                      },
                    ],
                    letter: "",
                  },
                },
              },
            },
          }}
          mapId={"popupPreview"}
          forcePopup={type === "popup"}
          forceTooltip={type === "tooltip"}
        />
      )}
      <Collapse defaultActiveKey={["visual"]} ghost expandIconPosition="right" accordion>
        <Panel header={<strong>Apparence</strong>} key="visual">
          <Space direction="vertical" style={{width: '100%'}}>
            <div>
              <p>Couleur du fond</p>
              <InputColor
                value={style?.background}
                onChange={(value) => updateElement("background", value)}
              />
            </div>
            <div>
              <p>Paddings</p>
              <Slider
                value={parseFloat(style?.paddingTop)}
                max={50}
                min={0}
                onChange={(value) => handlePadding(value)}
              />
            </div>
            <div>
              <p>Epaisseur de la bordure</p>
              <Slider
                value={parseFloat(style?.borderWidth)}
                max={20}
                min={0}
                onChange={(value) => updateElement("borderWidth", value + "px")}
              />
            </div>
            <div>
              <p>Arrondir la bordure</p>
              <Slider
                value={parseFloat(style?.borderRadius)}
                max={20} min={0}
                onChange={(value) =>
                  updateElement("borderRadius", value + "px")
                }
              />
            </div>
            <div>
              <p>Couleur de la bordure</p>
              <InputColor
                value={style?.borderColor}
                onChange={(value) => updateElement("borderColor", value)}
              />
            </div>
          </Space>
        </Panel>
        <Panel header={<strong>Contenu</strong>} key="content">
          <div>
            <p>Taille du texte</p>
            <InputNumber
              value={parseFloat(style?.fontSize) || 16}
              onChange={(value) => updateElement("fontSize", value + "px")}
            />
          </div>
          {/* <div>
                            <p>Police</p>
                            <Select >
                                <Select.Option>Police</Select.Option>
                                <Select.Option>Police</Select.Option>
                                <Select.Option>Police</Select.Option>
                            </Select>
                        </div> 
                        */}
          <div className="mb-default">
            <p>Couleur du texte</p>
            <InputColor
              value={style?.color}
              onChange={(value) => updateElement("color", value)}
            />
          </div>
          {type === "popup" && (
            <>
              <div className="flex justify-between">
                <span style={{ marginLeft: "1em" }}>Afficher le label </span>
                <Switch
                  size="small"
                  onChange={(checked) => updateElement("label", checked)}
                  checked={style?.label}
                />
              </div>
              <div className="flex justify-between">
                <span style={{ marginLeft: "1em" }}>Afficher la baseline </span>
                <Switch
                  size="small"
                  onChange={(checked) => updateElement("baseline", checked)}
                  checked={style?.baseline}
                />
              </div>
              <div className="flex justify-between">
                <span style={{ marginLeft: "1em" }}>
                  Afficher l'introduction{" "}
                </span>
                <Switch
                  size="small"
                  onChange={(checked) => updateElement("intro", checked)}
                  checked={style?.intro}
                />
              </div>
              <div className="flex justify-between">
                <span style={{ marginLeft: "1em" }}>Afficher le texte </span>
                <Switch
                  size="small"
                  onChange={(checked) => updateElement("text", checked)}
                  checked={style?.text}
                />
              </div>
              <div className="flex justify-between">
                <span style={{ marginLeft: "1em" }}>Afficher les medias </span>
                <Switch
                  size="small"
                  onChange={(checked) => updateElement("media", checked)}
                  checked={style?.media}
                />
              </div>
              <div className="flex justify-between">
                <span style={{ marginLeft: "1em" }}>Afficher les liens </span>
                <Switch
                  size="small"
                  onChange={(checked) => updateElement("link", checked)}
                  checked={style?.link}
                />
              </div>
            </>
          )}
        </Panel>
      </Collapse>
    </div>
  );
}
