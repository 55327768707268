import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { useEditorContext } from '../../../providers/EditorProvider';
import FeatureText from './FeatureText'
import FeatureApi from './FeatureApi'
import FeatureVisuals from './FeatureVisuals'
import { CloseOutlined } from "@ant-design/icons";
import MarkerEdit from '../styles/MarkerEdit';
import FeaturePathStyle from './FeaturePathStyle';

export default function FeatureEdit({ feature, close }) {

    const [editorState, editorDispatch] = useEditorContext()
    const { updateFeature, deleteFeature, canWaypoint } = editorDispatch;
    const [localFeature, setLocalFeature] = useState(null);

    useEffect(() => {
        // console.log(feature)
        if (!!feature)
            setLocalFeature({ ...feature })
    }, [feature])

    const handleUpdate = (data, updateSteps = true) => {
        let updatedFeature = { ...localFeature, ...data }
        updateFeature(updatedFeature, updatedFeature.type === 'step', null, updateSteps)
        setLocalFeature({ ...updatedFeature },)
    }

    const handleDelete = () => {
        deleteFeature(feature.id)
        close()
    }

    const closeBtn = (
        <div className="mr-default cursor-pointer">
            <CloseOutlined onClick={close} style={{ fontSize: 16 }} />
        </div>
    )

    return (
        <Tabs defaultActiveKey="text" tabBarExtraContent={closeBtn} style={{ maxHeight: '100%' }}>
            <Tabs.TabPane tab="Contenu" key="text">
                <FeatureText feature={feature} handleUpdate={handleUpdate} handleDelete={handleDelete} canWaypoint={canWaypoint}/>
            </Tabs.TabPane>
            {
                (feature.type === 'marker' || feature.type === 'layer') &&
                <Tabs.TabPane tab={feature.type === 'path' ? "Marqueur" : "Style"} key="style">
                    <MarkerEdit feature={localFeature} property={'marker'} handleUpdate={handleUpdate}/>
                </Tabs.TabPane>
            }
            {
                feature.type === 'path' &&
                <Tabs.TabPane tab="Style" key="pathStyle">
                    <FeaturePathStyle feature={localFeature}  handleUpdate={handleUpdate} />
                </Tabs.TabPane>
            }
            {
                feature.type !== 'api' &&
                <Tabs.TabPane tab="Medias" key="medias">
                    <FeatureVisuals feature={localFeature} handleUpdate={handleUpdate} />
                </Tabs.TabPane>
            }
            {
                feature.type === 'api' &&
                <Tabs.TabPane tab="Source" key="apiSource">
                    <FeatureApi feature={feature} handleUpdate={handleUpdate} />
                </Tabs.TabPane>
            }
        </Tabs>
    )
}
