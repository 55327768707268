import React from 'react';
import {Slider, Space} from "antd";

const AdvancedLayerSetting = ({layer, setLayer, type}) => {

    function setAnchor(axe, value) {
        let _copy = [...layer?.anchor];

        if (axe === 'x')
            _copy[1] = value / 100;
        else if (axe === 'y')
            _copy[0] = value / 100;

        setLayer('anchor', _copy);
    }

    return (
        <Space direction="vertical" style={{width: '100%'}}>
            <div>
                <p className='bold'>{type}</p>
            </div>

            <div>
                <div>Opacité ({type})</div>
                <Slider
                    value={layer?.opacity * 100}
                    onChange={value => setLayer('opacity', value / 100)}
                />
            </div>

            <div>
                <div>Ratio ({type})</div>
                <Slider
                    value={layer?.ratio * 100}
                    onChange={value => setLayer('ratio', value / 100)}
                    max={500}
                />
            </div>

            {layer?.anchor &&
                <div>
                    <div>Point d'ancrage du layer ({type})</div>
                    <div>x</div>
                    <Slider
                        min={0} max={100}
                        value={layer?.anchor[1] * 100}
                        onChange={value => setAnchor('x', value)}
                    />
                    <div>y</div>
                    <Slider
                        min={0} max={100}
                        value={layer?.anchor[0] * 100}
                        onChange={value => setAnchor('y', value)}
                    />
                </div>}
        </Space>
    );
};

export default AdvancedLayerSetting;