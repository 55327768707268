import React, { useCallback, useEffect, useState } from 'react'
import { Button, Spin } from "antd";
import FirstConnect from "../components/editor/FirstConnect";

import widget from "../components/WidgetComponent.js"; // client/widget component

export default function Home() {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isFirstConnect, setIsFirstConnect] = useState(false);

    useEffect(() => {
        widget.on('setData',listenMessage);
        return () => widget.off('setData',listenMessage);
    })

    const listenMessage = useCallback(data => {
        setData(data)
        setLoading(false)
    })

    function handleClick() {
        widget.fire('save',data,'broadcast');
        window.close();
    }

    return (
        <div>
            {isFirstConnect ? <FirstConnect setIsFirstConnect={setIsFirstConnect} />
                :
                <div>
                    <h1>Editions d'une cartographie</h1>

                    <div className="block-container mt-40">
                        <Spin spinning={false}>
                            <div>
                                <pre>
                                    {JSON.stringify(data)}
                                </pre>
                            </div>

                            <div>
                                <Button onClick={handleClick}>Send</Button>
                            </div>
                        </Spin>
                    </div>
                </div>}
        </div>
    )
}

