import React, { useState } from 'react'
import { AutoComplete } from 'antd';
import { useEditorContext } from '../../providers/EditorProvider';

const toolsEndpoint = "https://tools.mmcreation.com/osmap/geocode/"

export default function LocationAutoComplete({ handleCreate }) {

	const [editorState, editorDispatch] = useEditorContext()

    const [value, setValue] = useState('')
    const [options, setOptions] = useState([])
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [loading, setLoading] = useState(false)

    function onSelect(value, option) {
        //console.log(option)

        let name = option.label.split(',')[0]
        let baseline = option.label.replace(name + ',', '')
        let data = {
            label: { },
            baseline: { },
            coordinate: [option.latitude, option.longitude]
        }

        for(let i in editorState.languages)
        {
        	let l = editorState.languages[i];
        	data.label[l.value] = name.trim();
        	data.baseline[l.value] = baseline.trim();
        }

        handleCreate(data, 'marker')
        setValue('')
        setOptions([])
    }


    function onChange(query) {

        setLoading(true)
        setValue(query)
        setOptions([])
        clearTimeout(searchTimeout);

        setSearchTimeout(setTimeout(async () => {
            if (!query) return
            const results = await getJSON(query.trim())
            if (results.status === 'success') {
                let data = formatOptions(results.data)
                //console.log(data)
                setOptions(data)
            }
            setLoading(false)
        }, 500));
    }

    async function getJSON(query) {
        query = encodeURI(query)
        let url = toolsEndpoint + query + '.json'
        const response = await fetch(url);
        if (!response.ok) // check if response worked (no 404 errors etc...)
            throw new Error(response.statusText);

        const data = response.json(); // get JSON from the response
        return data; // returns a promise, which resolves to this data value
    }

    function formatOptions(data) {
        let names = []

        return data.reduce((accumulator, item) => {
            if (names.includes(item.name)) return accumulator;
            names.push(item.name)
            delete item.boundingBox
            return [...accumulator, { ...item, value: item.name, label: item.name }]
        }, [])
    }


    return (
        <AutoComplete
            value={value}
            options={options}
            style={{ width: '100%' }}
            onSelect={onSelect}
            onChange={onChange}
            placeholder="Rechercher un lieu précis"
            loading={loading}
        />
    )
}
