import { Button } from 'antd';
import React, { useEffect, useState } from 'react'
import { useEditorContext } from '../../../providers/EditorProvider'
import EditPathContent from './path/EditPathContent'

export default function PathEdit({ feature, handleUpdate, menu = null }) {

    const [editorState, editorDispatch] = useEditorContext()
    const { updateStyle, deleteStyle, deepCopy, updateFeature } = editorDispatch;
    const [pathStyle, setPathStyle] = useState(null)


    useEffect(() => {
        let styleId = null
        if (feature) {
            styleId = feature.style.path
        }

        let currentStyle = styleId ? editorState?.styles.path.find(item => item.id === styleId) : editorState.defaults.styles.path
        setPathStyle(deepCopy(currentStyle))

    }, [feature, editorState.reloadMap])

    function handleSave(style) {
        //console.log(style)
        if (feature && !style.id) {
            style.id = 'path_' + (new Date()).getTime();
            handleUpdate({ ...feature, style: { ...feature.style, path: style.id } })
        }
        setPathStyle(style)
        updateStyle('path', { ...style }, !feature)
    }

    function resetStyle() {
        if (pathStyle.id) {
            deleteStyle('path', pathStyle.id)
            updateFeature({ ...feature, style: { ...feature.style, path: null } })
            setPathStyle(editorState.defaults.styles.path)

        }
    }

    return (
        <div>
            {!!menu && menu()}
            {
                pathStyle &&
                <EditPathContent style={pathStyle} handleSave={handleSave} />
            }
            {
                pathStyle?.id !== "defaultPath" &&
                <div className='mv-1em'>
                    <Button size="small" onClick={resetStyle}>Reinitialiser</Button>
                </div>
            }
        </div>
    )
}
