import React, { useEffect, useState } from 'react'
import { Space } from 'antd'
import { CaretRightOutlined, EnvironmentOutlined } from '@ant-design/icons'

import apiData from './dummyApi.json'


export default function FeatureApi({ handleUpdate, feature }) {

    const [apiContent, setApiContent] = useState(apiData.data)
    const [tags, setTags] = useState(null)
    const [selectedApi, setSelectedApi] = useState(null)
    const [selectedMainParam, setSelectedMainParam] = useState(null)
    const [selectedSecondParam, setSelectedSecondParam] = useState(null)

    useEffect(() => {
        if (feature?.api?.endpoint) {
            let api = apiContent.find(item => item.endpoint === feature.api.endpoint)
            setSelectedApi(api)
            if (feature?.api?.parameters?.type) {
                let mainParam = api.param.values.find(item => item.key === feature?.api?.parameters?.type)
                setSelectedMainParam(mainParam)
            }
            if (api && feature?.api?.parameters?.tags) {
                let tagsData = parseTags(api)
                setSelectedSecondParam(tagsData.items.find(item => item.key === feature?.api?.parameters?.tags))
            }
        }
    }, [feature])

    useEffect(() => {

        if (selectedApi) {
            setTags(parseTags(selectedApi))
        } else {
            setTags(null)
        }
    }, [selectedApi])

    useEffect(() => {
        updateData()
    }, [selectedApi, selectedMainParam, selectedSecondParam])

    function parseTags(data) {

        let name = '';

        let tags = data.param.values.reduce((accumulator, current) => {
            //console.log(current)
            if (!current.param) return accumulator;

            name = current.param.name
            return [...accumulator, ...current.param.values]
        }, [])

        return { name: name, items: removeDuplicates(tags) }

    }

    function removeDuplicates(array) {
        return array.reduce((accumulator, current) => {
            if (accumulator.find(item => item.key === current.key)) return accumulator

            return [...accumulator, current]
        }, [])
    }

    function selectApi(api) {
        setSelectedApi(api)
        setSelectedMainParam(null)
        setSelectedSecondParam(null)

    }

    function selectParam(param, setter) {
        setter(param)
    }

    function updateData() {
        if (!selectedApi) return
        let tag = selectedSecondParam
        if (selectedMainParam) {
            tag = selectedMainParam?.param?.values ? selectedMainParam?.param?.values.find(item => item.key === selectedSecondParam?.key) : null
        }

        let api = {
            endpoint: selectedApi.endpoint,
            method: selectedApi?.method || 'get',
            parameters: {
                type: selectedMainParam?.key || null,
                tags: tag?.key || null
            }
        }
        handleUpdate({ api: api })
    }

    return (
        <div style={{ padding: '1em' }}>
            {!selectedApi &&
                <div style={{ marginBottom: '1em' }}>
                    Vous n'avez pas encore sélectionné de source
                </div>
            }
            <div >
                <h4 style={{ fontSize: '1em' }}>Sources disponibles:</h4>
                {
                    apiContent.map(item =>
                        <Space direction="vertical" style={{ width: '100%' }} key={item.name}>
                            <div key={item.name}
                                className={`column-line ${selectedApi?.name === item.name && !selectedMainParam && 'selected'}`}
                                onClick={() => selectApi(item)}>
                                <Space> <EnvironmentOutlined /> {item.name} </Space>
                                <div>
                                    <CaretRightOutlined />
                                </div>
                            </div>
                            {
                                item?.param?.values && item.param.values.map(child =>
                                    <div style={{ paddingLeft: '1em' }} key={`main_${item.key}`}
                                        className={`column-line ${selectedMainParam?.key === child.key && 'selected'}`}
                                        onClick={() => selectParam(child, setSelectedMainParam)}>
                                        {child.value}
                                    </div>
                                )
                            }
                        </Space>

                    )
                }
            </div>
            {
                selectedMainParam?.param &&
                <div style={{ marginTop: '1em' }}>
                    <h4 style={{ fontSize: '1em' }}>Filtrer par tag:</h4>
                    {
                        selectedMainParam.param.values.map(item =>
                            <div key={`second_${item.key}`} className={`column-line ${selectedSecondParam?.key === item.key && 'selected'}`} onClick={() => selectParam(item, setSelectedSecondParam)}>
                                <Space> <EnvironmentOutlined /> {item.value} </Space>
                            </div>
                        )
                    }
                </div>
            }
            {
                !selectedMainParam && !!tags?.items?.length &&
                <div style={{ marginTop: '1em' }}>
                    <h4 style={{ fontSize: '1em' }}>Filtrer par tag:</h4>
                    {
                        tags.items.map(item =>
                            <div key={`tags_${item.key}`} className={`column-line ${selectedSecondParam?.key === item.key && 'selected'}`} onClick={() => selectParam(item, setSelectedSecondParam)}>
                                <Space> <EnvironmentOutlined /> {item.value} </Space>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
}
