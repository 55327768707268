import React from 'react'
import AnchorSettings from './AnchorSettings'
import SizeSettings from './SizeSettings'

export default function MarkerSettings({ marker, handleEdit, isPro }) {

    return (
        <div className='pd-default'>
            <div >
                <p className="bold">Options du marqueur</p>
            </div>
            <SizeSettings marker={marker} updateMarker={handleEdit} />
            {
                isPro &&
                <AnchorSettings anchor={marker?.anchor} handleUpdate={(value) => handleEdit('anchor', value)} />
            }
        </div>
    )
}
