import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Select, InputNumber } from 'antd'
import InputColor from '../../../InputColor'

const baseText = {
    type: 'text',
    text: '',
    color: '#000000',
    font: 'normal 12px arial'
}

const fonts = [
    "Arial",
    "Verdana",
    "Helvetica",
    "Tahoma",
    "Trebuchet MS",
    "Times New Roman",
    "Georgia",
    "Garamond",
    "Courier New",
    "Brush Script MT"
]

export default function EditTextLayer({ layer, setLayer, deleteLayer }) {

    const [localLayer, setLocalLayer] = useState(baseText)
    const [fontSize, setFontSize] = useState('12px');
    const [fontFamily, setFontFamily] = useState('Arial');
    const [fontStyle, setFontStyle] = useState('normal');
    const [needUpdate, setNeedUpdate] = useState(0);

    useEffect(() => {
        if (!layer) {
            return setLocalLayer(baseText)
        }
        if (JSON.stringify(layer) !== JSON.stringify(localLayer)) {
            setLocalLayer(layer)
            handleFont(layer.font)
        }
    }, [layer])

    useEffect(() => {
        if (needUpdate) {
            let font = `${fontStyle} ${fontSize} ${fontFamily}`
            handleChange('font', font)
        }

    }, [needUpdate])

    const handleFont = (font) => {
        //console.log('ff', font)
        let fontData = font.split(' ');
        fontData.map(item => {
            if (item === 'normal' || item === 'italic') {
                return setFontStyle(item)
            }
            if (fonts.includes(item)) {
                return setFontFamily(item)
            }
            if (typeof parseFloat(item) === 'number') {
                return setFontSize(item)
            }
        })
    }

    const handleChange = (property, value) => {
        //console.log(value)
        let newLayer = { ...localLayer, [property]: value }
        setLocalLayer(newLayer)
        setLayer(newLayer)
    }

    const handleDelete = () => {
        deleteLayer(localLayer.id)
        setLocalLayer(baseText)
    }

    const handleFontChange = (setter, value) => {
        setter(value)
        setNeedUpdate((new Date()).getTime())
    }

    return (
        <div>
            <Space direction='vertical' style={{ width: '100%' }}>

                <div >Texte</div>
                <Input
                    value={localLayer?.text}
                    placeholder='Entrer un texte'
                    onChange={e => handleChange('text', e.target.value)}
                />

                <div>Couleur</div>
                <InputColor
                    value={localLayer?.color}
                    onChange={(value) => handleChange('color', value)}
                />

                <div >Police</div>
                <Select style={{ width: '200px' }} value={fontFamily} onChange={(value) => handleFontChange(setFontFamily, value)}>
                    {
                        fonts.map(item =>
                            <Select.Option key={item} value={item}>{item}</Select.Option>
                        )
                    }
                </Select>

                <div >Style de police</div>
                <Select style={{ width: '200px' }} value={fontStyle} onChange={(value) => handleFontChange(setFontStyle, value)}>
                    <Select.Option value="normal">Normal</Select.Option>
                    <Select.Option value='italic'>Italique</Select.Option>
                </Select>

                <div >Taille de la police</div>
                <InputNumber value={parseFloat(fontSize)} onChange={(value) => handleFontChange(setFontSize, value + 'px')} />
                {
                    localLayer?.id &&
                    <Button danger size="small" onClick={handleDelete} >Supprimer ce texte</Button>
                }
            </Space>
        </div>
    )
}
