import React, { useEffect, useState } from 'react';
import { useEditorContext } from "../../../../providers/EditorProvider";
import LevelCluster from "./LevelCluster";

const ClusterEdit = ({ feature, menu = null }) => {

    const [editorState, editorDispatch] = useEditorContext()
    const { deepCopy, updateFeature, deleteStyle, updateStyle } = editorDispatch

    const [editedCluster, setEditedCluster] = useState(null)

    useEffect(() => {
        let clusterId = null
        if (feature) {
            clusterId = feature.clusterId;
        }
        let _cluster = clusterId ? editorState?.styles.cluster.find(item => item.id === clusterId) : editorState.defaults.styles.cluster
        console.log(_cluster)
        setEditedCluster(deepCopy(_cluster))

    }, [feature, editorState.reloadMap])

    function handleSave(value) {

        if (feature && !editedCluster.id) {
            value.id = 'marker_' + new Date().getTime();
            value.name = feature.label.fr;
            updateFeature({ ...feature, style: value.id })
        }
        setEditedCluster({ ...value })
        updateStyle('cluster', { ...value }, !feature)
    }


    return (
        <div>
            {editedCluster &&
                <LevelCluster element={deepCopy(editedCluster)} setElement={handleSave} menu={menu} />}
        </div>
    );
};

export default ClusterEdit;