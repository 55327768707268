import React, { useEffect, useState } from 'react'
import { Avatar, Space, Switch } from 'antd'
import Icon, { ArrowLeftOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { useEditorContext } from '../../../providers/EditorProvider'
import FeatureTree from '../features/FeatureTree'
import PanelContentCarousel from './PanelContentCarousel'
import { ReactComponent as DeleteIcon } from '../../../assets/trash.svg'


export default function EditPanel({ panel, type, setVisible }) {

    const [editorState, editorDispatch] = useEditorContext()
    const { updatePanel, deletePanel, getFeatureName } = editorDispatch;
    const [localPanel, setLocalPanel] = useState(panel)
    const [source, setSource] = useState(panel?.src || null)
    const [isVisible, setIsVisible] = useState(panel?.visible || false)

    useEffect(() => {
        //console.log(panel.id)
        if (JSON.stringify(panel) !== JSON.stringify(localPanel)) {
            console.log('panel', panel)
            setLocalPanel(panel)
            setSource(panel.src || null)
        }
    }, [panel])

    useEffect(() => {
        updateState()
    }, [localPanel, source, isVisible])

    function close() {
        setVisible(null)
    }

    function updateState() {
        let pan = {
            ...localPanel,
            src: source,
            visible: isVisible
        }
        updatePanel(pan, type)
    }

    function handleDelete() {
        deletePanel(localPanel?.id, type)
        close()

    }

    return (
        <div>
            <div className='top-menu-item flex justify-between align-center'>
                <div>
                    <Avatar
                        onClick={close}
                        className='cursor-pointer'
                        style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                        icon={<ArrowLeftOutlined />} />
                    <span>Editer un panneau</span>
                </div>
                <Space>
                    {
                        isVisible ?
                            <EyeOutlined onClick={(e) => setIsVisible(false)} title="Rendre ce panneau invisible" style={{ color: '#2494d1' }} />
                            : <EyeInvisibleOutlined onClick={(e) => setIsVisible(true)} title="Rendre ce panneau visible" />
                    }
                    <Icon style={{ color: "#E64D3D" }} component={DeleteIcon} onClick={handleDelete} title="Supprimer ce panneau" />
                </Space>
            </div>
            <div>

                <div className='pd-default'>
                    <p className="light">Ici, vous pouvez gérer l’affichage des contenus au sein de votre panneau, en partant du dossier Racine que vous avez sélectionnez en source, jusqu’aux éléments qu’il contient.</p>
                    <FeatureTree value={source} updateValue={setSource} />
                </div>
                <PanelContentCarousel panel={localPanel} updatePanel={setLocalPanel} getFeatureName={getFeatureName} />
            </div>
        </div>

    )
}
