import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useEditorContext } from "../../../../providers/EditorProvider";
import { Hapi_ImagePicker } from "../../../ImagePicker";
import ModalFaIcon from "../../modal/ModalFaIcons";

export default function ExternalIcon({ current, handleSelect }) {

    const [editorState] = useEditorContext()

    const [imagePicker, setImagePicker] = useState(null)
    const [openFaModal, setOpenFaModal] = useState(false)

    useEffect(() => {
        if (!imagePicker) {
            let param = {
                endpoint: editorState.imagePicker.endpoint,
                connector: editorState.imagePicker.connector === 'hapidam' ? 'hapidam' : 'filemanager',
                token: editorState.imagePicker.token,
            }
            setImagePicker(new Hapi_ImagePicker(param))
        }
    }, [])

    function openImagePicker(e) {
        e.stopPropagation()

        imagePicker.close();
        // set image selection callback (after initialisation)
        // notice! callback can be set as a constructor parameter
        imagePicker.setCallback(image => handleSelect(image.url));
        // finally open the image picker
        imagePicker.open();

    }

    return (
        <>
            <div>
                <div className="flex justify-between">
                    <p>{current ? "Modifier l'icone" : "Insérer un icone"} </p>
                    {
                        current &&
                        <div><img style={{ width: '1em' }} src={current} alt="icon" /></div>
                    }
                </div>

                <Button onClick={e => openImagePicker(e)} size="small">
                    Personalisé
                </Button>
                <Button size="small" className='ml-default' onClick={() => setOpenFaModal(true)}>Font awesome</Button>
            </div>
            <ModalFaIcon visible={openFaModal} setVisible={setOpenFaModal} handleSelect={handleSelect} />
        </>
    )
}