import React, { useState } from 'react'
import { Avatar, Switch } from 'antd'
import Icon, { ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";

import PathEdit from './PathEdit'
import PopupEdit from './PopupEdit'
import ClusterEdit from "./cluster/ClusterEdit";
import { ReactComponent as IconMarker } from '../../../assets/marker.svg'
import { ReactComponent as IconCluster } from '../../../assets/cluster.svg'
import { ReactComponent as IconPopup } from '../../../assets/popup.svg'
import { ReactComponent as IconTooltip } from '../../../assets/tooltip.svg'
import { ReactComponent as IconPath } from '../../../assets/path.svg'
import MarkerEdit from './MarkerEdit';
import { useEditorContext } from '../../../providers/EditorProvider';

export default function Styles() {

    const [editorState, editorDispatch] = useEditorContext()
    const { setter } = editorDispatch

    const [currentTab, setCurrentTab] = useState(null)
    const nav = [
        { id: 'marker', title: "Marqueurs", icon: { default: IconMarker } },
        { id: 'cluster', title: "Groupes", icon: { default: IconCluster } },
        { id: 'popup', title: "Popups", icon: { default: IconPopup } },
        { id: 'tooltip', title: "Infos-bulles", icon: { default: IconTooltip } },
        { id: 'path', title: "Itinéraires", icon: { default: IconPath } }
    ];

    const getMenu = () => (
        <div className='top-menu-item'>
            <div>
                <Avatar
                    onClick={() => setCurrentTab(null)}
                    className='cursor-pointer'
                    style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                    icon={<ArrowLeftOutlined />} />
                <span style={{ marginRight: '1em' }}><Icon style={{ color: '#2494d1' }} component={currentTab.icon.default} /></span>
                <span>Modifier les {currentTab.title}</span>
            </div>
        </div>
    )

    if (currentTab) {
        return (
            <div>
                {
                    currentTab.id === 'marker' &&
                    <MarkerEdit menu={getMenu} />
                }
                {
                    currentTab.id === 'cluster' &&
                    <ClusterEdit menu={getMenu} />
                }
                {
                    currentTab.id === 'popup' &&
                    <PopupEdit type="popup" menu={getMenu} />
                }
                {
                    currentTab.id === 'tooltip' &&
                    <PopupEdit type="tooltip" menu={getMenu} />
                }
                {
                    currentTab.id === 'path' &&
                    <PathEdit menu={getMenu} />
                }
            </div>
        )
    }

    return (
        <div>
            <div className='pd-default'>
                <h3 className="no-margin">Editer les styles par défaut</h3>
                <p className="light">Les styles par défaut de chaque type d'élément peut être facilement modifier.</p>
            </div>
            {
                nav.map(item =>
                    <div className='style-menu-item' onClick={() => setCurrentTab(item)} key={item.id}>
                        <div>
                            <Avatar style={{ backgroundColor: '#2494d1', marginRight: '1em' }} icon={<Icon style={{ color: 'white' }} component={item.icon.default} />} />
                            <span>{item.title}</span>
                        </div>
                        <RightOutlined />
                    </div>
                )
            }
            <div style={{ position: 'absolute', bottom: '0', padding: '1em' }}>
                <Switch size="small" checked={editorState.isProUser} onClick={(checked) => setter('isProUser', checked)} /> Pro mode
            </div>
        </div>
    )
}
