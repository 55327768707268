import React from "react";
import {SketchPicker} from "react-color";
import {Input, Popover} from "antd";
import Icon, {BgColorsOutlined} from "@ant-design/icons";
import {ReactComponent as Drop} from '../assets/drop.svg'

export default function InputColor({value = "", onChange = () => {}}) {

    function handleRGB(color) {
        let {r, g, b, a} = color;
        return `rgba(${r}, ${g}, ${b}, ${a})`
    }

    function handleChange(value) {
        const rgb = handleRGB(value.rgb);
        onChange(rgb);
    }

    const colorPicker = (
        <SketchPicker
            color={value}
            onChange={handleChange}
            onClick={e => {
                e.stopPropagation()
                e.preventDefault()
            }}
            width={316}
        />
    )

    const popover = (
        <Popover
            title={null}
            content={colorPicker}
            trigger="click"
            onClick={e => e.stopPropagation()}
            overlayInnerStyle={{padding: 0, width: 336}}
            overlayClassName="input-color-tooltip"
        >
            <BgColorsOutlined/>
        </Popover>
    )

    return (
        <Input
            size="large"
            prefix={<Icon component={Drop} style={{color: value}}/>}
            suffix={popover}
            style={{width: 250, maxWidth: '100%'}}
            value={value}
        />
    )
}