import { Switch, Avatar, Radio, Button, Space, Tooltip, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useEditorContext } from '../../../providers/EditorProvider'
import { AimOutlined, CloseCircleOutlined, PictureOutlined, QuestionCircleOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons'
import RasterList from '../RasterList'
import viewGif from '../../../assets/hapimap-view-selection.gif'

let drawingBounds = [];
let initialBounds = [];
let listenerActive = false

export default function Publish({ isDrawing, setIsDrawing }) {

    const [editorState, editorDispatch] = useEditorContext()
    const { restartMap, setter } = editorDispatch
    const [controls, setControls] = useState({})
    const [rasterEdit, setRasterEdit] = useState(false)
    const [mode, setMode] = useState('auto')
    const [_zoom, setZoom] = useState(null)

    useEffect(() => {
        setControls({ ...editorState.controls })
        let _mode = editorState.bounds === 'auto' ? 'auto' : undefined
        setMode(_mode)
        if (_mode === 'auto') { setZoom(editorState?.zoom) }

        if (isDrawing) { startDrawing() }
        return (() => {
            if (isDrawing) {
                stopDrawing()
            }
        })
    }, [isDrawing, editorState.restartMap])


    function handleControlsUpdate(property, value) {
        setControls(prev => { return { ...prev, [property]: value } })
        setter('controls', { ...controls, [property]: value })
    }

    if (rasterEdit) {
        return <RasterList setRasterEdit={setRasterEdit} />
    }

    function handleBoundsChange(e) {
        // console.log('val', e)
        if (isDrawing) stopDrawing()
        let _bounds = e.target?.value;
        setMode(_bounds)
        setter('bounds', _bounds)
        setter('center', null);
        if (_bounds === 'auto') {
            setter('zoom', _zoom);
        } else {
            let newBounds = initialBounds?.length ? initialBounds : editorState.hapimap.getViewBounds()
            setter('bounds', newBounds)
            setter('zoom', null);
        }
        restartMap();

    }

    function handleZoom() {
        let zoom = editorState.hapimap.getZoom()
        setZoom(zoom)
        setter('zoom', zoom)
        message.success('Le zoom a été mis à jour.')
    }

    function resetZoom() {
        setter('zoom', null)
        setZoom(null)
        restartMap();
        message.success('Le zoom a été mis à jour.')
    }

    function startDrawing() {
        editorState.hapimap.startBoundsEdit()
        addBoundsChangeListener()
    }

    function stopDrawing() {
        editorState.hapimap.stopBoundsEdit()
        setIsDrawing(false)
    }

    function saveDrawingBounds() {
        if (drawingBounds.length) {
            setter('bounds', drawingBounds)
            setter('zoom', null);
            initialBounds = drawingBounds
            message.success('La position a été mis à jour.')
        }
        stopDrawing()
        restartMap();
    }

    function addBoundsChangeListener() {

        if (!listenerActive) {
            listenerActive = true;
            editorState.hapimap.on('bounds.changed', (data) => {
                drawingBounds = data
            });
        }
    }

    return (
        <div >
            <div className='pd-default'>
                <h3 className="no-margin">Publier votre carte</h3>
                <p className="light">Faites les derniers réglages et valider votre carte.</p>
            </div>
            <div className='style-menu-item' onClick={() => setRasterEdit(true)}>
                <div>
                    <Avatar style={{ backgroundColor: '#2494d1', marginRight: '1em' }} icon={<PictureOutlined />} />
                    <span>Fond de la carte</span>
                </div>
                <RightOutlined />
            </div>
            <div className='pd-default'>
                <p className='bold'>Affichage des contrôles</p>

                <div className='flex justify-between'>
                    <span>Plein écran</span>
                    <Switch checked={controls?.fullscreen} onChange={(value) => handleControlsUpdate('fullscreen', value)} size="small" />
                </div>
                <div className='flex justify-between'>
                    <span>Zoom</span>
                    <Switch checked={controls?.zoom} onChange={(value) => handleControlsUpdate('zoom', value)} size="small" />
                </div>
                <div className='flex justify-between'>
                    <span>Reset du zoom</span>
                    <Switch checked={controls?.reset} onChange={(value) => handleControlsUpdate('reset', value)} size="small" />

                </div>
                <div className='flex justify-between'>
                    <span>Contrôle de visibilité des dossiers</span>
                    <Switch checked={controls?.layerswitcher} onChange={(value) => handleControlsUpdate('layerswitcher', value)} size="small" />
                </div>

                <div className='flex justify-between'>
                    <span>Choix du fond de carte</span>
                    <Switch checked={controls?.tilesetswitcher} onChange={(value) => handleControlsUpdate('tilesetswitcher', value)} size="small" />
                </div>

                <div className='mt-default'>
                    <p className='bold'>Position lors de l'ouverture
                        <Tooltip title="Afficher la carte avec les réglages actuels">
                            <Button size="small" icon={<SyncOutlined />} style={{ float: 'right' }} onClick={restartMap} />
                        </Tooltip>
                    </p>
                    <Radio.Group onChange={handleBoundsChange} value={mode}>
                        <Radio value="auto">Automatique</Radio>
                        <Radio value={undefined}>Prédéfini </Radio>
                    </Radio.Group>
                    {
                        mode === 'auto' ?
                            <div className='mt-default'>
                                <p className='subtitle'>L'affichage initial sera définit en fonction des éléments présent.</p>
                                <p className='subtitle'>Le zoom peut être automatique ou vous pouvez définir le maximum en cliquant ci-dessous.</p>
                                <Space>
                                    <Button onClick={handleZoom} icon={<AimOutlined />}>Valider le zoom maximum</Button>
                                    <Button onClick={resetZoom} icon={<CloseCircleOutlined />} title="Revenir à un zoom automatique" disabled={!editorState?.zoom} />
                                </Space>
                            </div>
                            :
                            <div className='mt-default'>
                                <p className='subtitle'>
                                    Sélectionner une zone sur la carte. Cette zone sera la zone d'affichage initial de votre carte.
                                </p>
                                <p>
                                    <Tooltip title={(
                                        <img src={viewGif} alt="Sélection demo" className='w100-per' />
                                    )} destroyTooltipOnHide placement='right'>
                                        <QuestionCircleOutlined className='mr-small' />
                                    </Tooltip>
                                    Cliquez sur la carte pour sélectionner une zone.
                                </p>
                                {
                                    !isDrawing ?
                                        <Button onClick={() => setIsDrawing(true)}>Définir une zone</Button>
                                        :
                                        <Space>
                                            <Button onClick={stopDrawing}>Annuler</Button>
                                            <Button type="primary" onClick={saveDrawingBounds}>Valider</Button>
                                        </Space>
                                }
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}
