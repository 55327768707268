import React, {useRef} from 'react'
import {Button, Card, Carousel, Space} from "antd";
import Icon, {ArrowRightOutlined} from "@ant-design/icons";
import {useEditorContext} from "../../providers/EditorProvider";
import {ReactComponent as FeatureIcon} from '../../assets/feature-frame.svg'
import {ReactComponent as StyleIcon} from '../../assets/style-frame.svg'
import {ReactComponent as PanelIcon} from '../../assets/pannel-frame.svg'

export default function FirstConnect() {

    const [editorState, editorDispatch] = useEditorContext();
    const {setIsFirstConnect} = editorDispatch;
    const carousel = useRef(null);

    function handleClick() {
        setIsFirstConnect(false)
    }

    return (
        <div>
            <h1>Création d'une cartographie</h1>

            <Carousel dots={false} effect="fade" ref={carousel}>
                <div className="block-container mt-40 relative">
                    <h2 className="subtitle">Juste un petit mot avant de commencer...</h2>
                    <h2>Il semblerait que vous n’ayez pas encore créé de cartographies à l’heure actuelle...</h2>

                    <p className="pd-30 big">
                        Un petit tour d’horizon rapide (pas plus d’une minute, promis !), pour bien connaître l’outil,
                        ça
                        vous tenterait ?
                    </p>

                    <div className="mt-40">
                        <Space>
                            <Button
                                size="large" type="primary" icon={<ArrowRightOutlined/>}
                                onClick={() => carousel.current.goTo(1)}
                            >
                                Oui, montrez-moi !
                            </Button>
                            <Button
                                size="large" icon={<ArrowRightOutlined/>}
                                onClick={handleClick}
                            >
                                Non merci, je connais déjà l’outil !
                            </Button>
                        </Space>
                    </div>
                </div>

                <div className="block-container mt-40 relative mb-40">
                    <h2 className="subtitle">Super, c'est partit ! &#128515;</h2>
                    <h2>Sur HapiMap, vous pouvez créer vos propres cartographies, avec vos propres marqueurs et points
                        d'intérêts.</h2>

                    <p className="big pd-30">
                        Vous pourrez intégrer toute sorte de points d'intérêts "globaux" comme des monuments, des
                        hôtels,
                        des
                        restaurants... Mais aussi des bien plus spécifiques: des monuments gratuits ou payants, des
                        hôtels a
                        concept de luxe, des restaurants italiens ou japonais... Bref beaucoup de contenus différrent!
                        HapiMap vous permettra de gérer ces types de contenus ainsi que leurs apparences, affichage et
                        comportement.
                    </p>

                    <p className="big pd-30">
                        Pour ce faire, la création de cartographie sur HapiMap repose sur 3 grandes parties:
                    </p>

                    <Space wrap align="stretch" className="mb-40">
                        <Card style={{width: 350, backgroundColor: "#eee", height: "100%"}}>
                            <div>
                                <Icon component={StyleIcon} style={{fontSize: 45}}/>
                            </div>

                            <h3 className="mt-10">Les styles d'habillage</h3>

                            <p>
                                Il s'agit de la première partit, dans laquelle vous allez pouvoir créer et préparer vos
                                propres habillages. Vous pourrez même habiller vos différents types de marqueurs, d'une
                                manière bien distinct.
                            </p>
                        </Card>

                        <Card style={{width: 350, backgroundColor: "#eee", height: "100%"}}>
                            <div>
                                <Icon component={FeatureIcon} style={{fontSize: 45}}/>
                            </div>

                            <h3 className="mt-10">Les Contenus</h3>

                            <p>
                                Une fois que vous avez défini vos styles d'habillage, vous pourrez les appliquer a des
                                contenus. En l'occurence, les marqueurs que l'on pourra voir sur votre cartographie. Ces
                                contenus vous serons présentés sous la forme d'un tableau qui vous permettra de ranger
                                les
                                différents contenus.

                            </p>
                        </Card>

                        <Card style={{width: 350, backgroundColor: "#eee", height: "100%"}}>
                            <div>
                                <Icon component={PanelIcon} style={{fontSize: 45}}/>
                            </div>

                            <h3 className="mt-10">Les panneaux</h3>

                            <p>
                                Il s'agira de la source d'information maîtresse, celle que l'internaute verra pour avoir
                                des
                                informations plus complètes sur certains poits d'intérêts.
                            </p>
                        </Card>
                    </Space>

                    <Button
                        type="primary" className="next-step-btn" size="large"
                        onClick={handleClick}
                    >
                        <ArrowRightOutlined/> Ok, c'est bon pour moi
                    </Button>
                </div>
            </Carousel>
        </div>
    )
}
