import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import "./styles/theme.less";
import "./styles/App.scss";

import Router from './components/Router.js';
import {AuthProvider} from "./providers/AuthProvider";

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Router/>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;

