import React from 'react'
import { Button, Form } from 'antd'
import TextInput from '../TextInput'
import LinkInput from '../LinkInput'
import StepOptions from './StepOptions'

export default function FeatureText({ feature, handleUpdate, handleDelete, canWaypoint}) {

    return (
        <Form layout="vertical" style={{ padding: '1em' }}>
            {
                feature.type === 'step' &&
                <StepOptions feature={feature} handleUpdate={handleUpdate} canWaypoint={canWaypoint} />
            }
            {
                feature.type === 'step' &&
                <p className='subtitle'>Ce contenu apparait uniquement dans des panneaux</p>
            }
            <TextInput handleUpdate={handleUpdate} data={feature?.label} name={'label'} label={"Nom"} placeholder="Saisissez le nom de votre nouveau contenu" />
            <TextInput handleUpdate={handleUpdate} data={feature?.baseline} name={'baseline'} label={"Baseline"} placeholder="Saisissez la baseline" />
            <TextInput handleUpdate={handleUpdate} data={feature?.intro} name={'intro'} label={"Introduction"} placeholder="Saisissez une introduction" />
            {
                feature?.type === 'marker' &&
                <LinkInput handleUpdate={handleUpdate} data={feature?.link} name={'link'} label={"Insérer un lien ?"} />
            }
            <TextInput handleUpdate={handleUpdate} data={feature?.text} name={'text'} label={"Texte"} textArea />

            {
                feature.type !== 'step' &&
                <Button onClick={handleDelete} type="danger">Supprimer cet élément</Button>
            }
        </Form>
    )
}
