import React, { useEffect, useState } from 'react'
import { Modal, Button, Pagination, Input, Spin } from 'antd'

import axios from "axios";
import { library, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas);
library.add(far);

const FONTAWESOME6_ENDPOINT = process.env.FONTAWESOME6_ENDPOINT || 'https://tools.mmcreation.com/fontawesome/v6/';

let solidIcons = Object.keys(fas).reduce((acc, current) => {

    let iconElement = findIconDefinition({ prefix: 'fas', iconName: fas[current].iconName })
    if (!iconElement || acc.includes(iconElement.iconName)) return acc

    return [...acc, iconElement.iconName]
}, [])

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ModalFaIcon({ visible, setVisible, handleSelect }) {

    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [results, setResults] = useState([])
    const [pagination, setPagination] = useState(null)

    const FaIcons = { solid: 'fas', regular: 'far' }

    useEffect(() => {
        setPagination({
            current: 1,
            total: Math.floor(solidIcons.length),
            pageSize: 50
        })
    }, [])

    const handleFASearch = async (query) => {
        setLoading(true)
        setSelected(null)
        var response = await axios.post('https://api.fontawesome.com/', { "query": `query { search(version: "6.0.0", query: "${query}", first: 20) { id, styles, label } }` })
        // console.log('response', response)
        let icons = response?.data?.data?.search

        if (icons) {
            // console.log('icons', icons)
            setResults(icons)
        }
        setLoading(false)
    }

    const getIcon = (icon, style) => {

        // let name = 'fa' + icon.label.split(' ').join('');
        let iconElement = findIconDefinition({ prefix: FaIcons[style], iconName: icon.id })
        if (!iconElement || !FaIcons[style]) return '';

        return (
            <div className={`icon pointer ${icon.id === selected?.name && 'selected'}`} key={`fa_${style}_${icon.id}`} onClick={() => setSelected({ style, name: icon.id })}>
                <FontAwesomeIcon icon={`fa-${style} fa-${icon.id}`} color={"#2494d1"} />
            </div>)
    }

    const getSolidList = () => {
        let start = 40 * (pagination.current - 1)
        let iconNames = solidIcons.slice(start, start + 40)
        // console.log('first', iconNames)

        return iconNames.map(function (name) {
            return (
                <div title={name} className={`icon pointer ${name === selected?.name && 'selected'}`}
                    key={`fa_fas_${name}`} onClick={() => setSelected({ style: 'solid', name: name })}>
                    <FontAwesomeIcon icon={`fa-fas fa-${[name]}`} color={"#2494d1"} />
                </div>
            )
        });
    }

    const handlePage = (page) => {
        setPagination(prev => { return { ...prev, current: page } })
    }

    const handleSave = () => {
        let url = `${FONTAWESOME6_ENDPOINT}${selected.style}/${selected?.name}.svg`
        // console.log('link', url)
        handleSelect(url)
        setVisible(false)
        setResults([])
    }

    return (
        <Modal visible={visible}
            width='750px'
            footer={[
                <Button size="large" onClick={() => setVisible(false)}>Annuler</Button>,
                selected &&
                <Button onClick={handleSave} size="large" className="success ml-default" >
                    <span >Ajouter l'icone : <FontAwesomeIcon className='ml-default' icon={`fa-${selected.style} fa-${selected.name}`} /></span>
                </Button>
            ]}
            onCancel={() => setVisible(false)}>
            <div style={{ padding: '40px 40px 12px' }}>
                <div>
                    <h3>Ajouter un icone Font awesome</h3>
                </div>
                <p>Rechercher et sélectionner l'icone à ajouter</p>
                <div className='flex justify-between'>
                    <div className='input-middle'>
                        <Input.Search allowClear placeholder="Rechercher un icone" onSearch={handleFASearch} style={{ width: '250px' }} />
                    </div>
                    {
                        !results.length && pagination && <Pagination current={pagination?.current}
                            onChange={handlePage} total={pagination.total}
                            pageSize={pagination?.pageSize} size="small"
                            showLessItems
                            showSizeChanger={false} />
                    }
                </div>
                <Spin spinning={loading}>
                    <div className="mt-20 icon-list">
                        {
                            !!results.length && results.map(icon => icon.styles.map(style => getIcon(icon, style)))
                        }
                        {
                            !results.length && pagination && getSolidList()
                        }
                    </div>
                </Spin>
            </div>
        </Modal>
    )
}
