import React from 'react'
import { Avatar, Icon, Space } from 'antd'
import { useEditorContext } from '../../providers/EditorProvider'
import { rasterList } from './helpers'
import { ArrowLeftOutlined, PictureOutlined } from '@ant-design/icons'

export default function RasterList({ setRasterEdit }) {

    const [editorState, editorDispatch] = useEditorContext()
    const { setter } = editorDispatch

    function handleBack(e) {
        e.stopPropagation()
        setRasterEdit(false)
    }
    return (
        <>
            <div className='top-menu-item'>
                <div>
                    <Avatar
                        onClick={handleBack}
                        className='cursor-pointer'
                        style={{ backgroundColor: '#2494d1', marginRight: '1em' }}
                        icon={<ArrowLeftOutlined />} />
                    <span style={{ marginRight: '1em' }}><PictureOutlined  style={{ color: '#2494d1' }}  /></span>
                    <span>Modifier le fond de la carte</span>
                </div>
            </div>
            <div className='pd-default'>
                <Space direction='vertical' style={{ width: '100%' }}>
                    {
                        rasterList.map(item =>
                            <div key={item} className={`raster-item ${editorState.raster === item ? 'selected' : ''}`}
                                style={{ background: `url(https://tools.mmcreation.com/osmap/tiles/${item}/13/4149/2818.png)` }}
                                onClick={() => setter('raster', item)}>
                            </div>
                        )
                    }
                </Space>
            </div>
        </>
    )
}
