import React, { useEffect, useState } from 'react'
import { Form, message, Select, Switch } from 'antd'

export default function StepOptions({ feature, handleUpdate, canWaypoint }) {

    const [vehicle, setVehicle] = useState(null)
    const [waypoint, setWaypoint] = useState(false)

    useEffect(() => {
        setVehicle(feature.vehicle || 'car')
        setWaypoint(feature.waypoint)
    }, [feature])

    const handleChange = (type, value) => {
        if (type === 'vehicle') {
            setVehicle(value)
        } else {
            let findStepIndex = canWaypoint(feature.id)
            if (!findStepIndex) return message.warning('Le premier et dernier points ne peuvent être des points de passage.')
                setWaypoint(value)
        }
        let data = { [type]: value }

        handleUpdate(data)
    }


    return (
        <>
            <Form.Item label="Moyen de transport">
                <Select value={vehicle} style={{ width: '100%' }} onChange={(value) => handleChange('vehicle', value)}>
                    <Select.Option value={'car'}>Voiture</Select.Option>
                    <Select.Option value={'bike'}>Vélo</Select.Option>
                    <Select.Option value={'foot'}>A pied</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="Point de passage">
                <Switch checked={waypoint} onChange={(checked) => handleChange('waypoint', checked)} />
            </Form.Item>
        </>
    )
}
