import React, { useEffect, useState } from 'react'
import { Collapse } from 'antd';
import EditShapeLayer from './EditShapeLayer';
import EditIconLayer from './EditIconLayer';
import EditTextLayer from './EditTextLayer';
import AdvancedSetting from "./AdvancedSetting";

export default function SimpleSettings({ marker, setMarker }) {

    const [layers, setLayers] = useState([]);

    useEffect(() => {

        if (marker?.layers) {
            setLayers([...marker.layers]);
        } else {
            setLayers([])
        }
    }, [marker])

    const handleLayerEdit = (newLayer) => {
        let newLayers;
        if (newLayer?.id) {
            newLayers = layers.map(layer => layer.id === newLayer.id ? newLayer : layer)
        } else {
            newLayer.id = 'layer_' + (new Date()).getTime()
            //récupere l'index pour avoir la forme derriere l'icone et le text au dessus : forme > icone > text
            let index = 0;
            if (newLayer.type === 'icon') index = 1;
            if (newLayer.type === 'text') index = 2;

            newLayers = [...layers]
            newLayers.splice(index, 0, newLayer)

        }
        setMarker({ ...marker, layers: newLayers })
    }

    const deleteLayer = (id) => {
        let newLayers = layers.filter(layer => layer.id !== id)
        setMarker({ ...marker, layers: newLayers })
    }


    return (
        <>
            {
                marker &&
                <Collapse ghost defaultActiveKey={["shape"]} expandIconPosition="right" accordion >
                    <Collapse.Panel header={<strong>Forme</strong>} key='shape'>
                        <EditShapeLayer isPro={false} layer={layers.find(item => item.type === 'symbole' || item.type === 'shape')} setLayer={handleLayerEdit} deleteLayer={deleteLayer} />
                    </Collapse.Panel>
                    <Collapse.Panel header={<strong>Icone</strong>} key='icon'>
                        <EditIconLayer isPro={false} layer={layers.find(item => item.type === 'icon')} setLayer={handleLayerEdit} deleteLayer={deleteLayer} />
                    </Collapse.Panel>
                    <Collapse.Panel header={<strong>Texte</strong>} key='text'>
                        <EditTextLayer layer={layers.find(item => item.type === 'text')} setLayer={handleLayerEdit} deleteLayer={deleteLayer} />
                    </Collapse.Panel>
                    <Collapse.Panel header={<strong>Avancé</strong>} key='advanced'>
                        <AdvancedSetting layers={layers} setLayer={handleLayerEdit} marker={marker} setMarker={setMarker} />
                    </Collapse.Panel>
                </Collapse>
            }
        </>
    )
}
