import React, { useContext, useState } from 'react';
import { useImmer } from 'use-immer';

// ---------------------------------------------------
// Default contextual state values
// ---------------------------------------------------

let lastLog = 'push'
let _defaultMap = {
    "fileFormat": "hapimap/1.0",
    "ctime": new Date(),
    "mtime": new Date(),
    "raster": "default",
    // "center": [48.856614, 2.3522219],
    // "zoom": 55,
    "bounds": "auto",
    "controls": {
        "layerswitcher": false,
        "tilesetswitcher": false,
        "zoom": true
    },
    "label": {
        "fr": "Nouvelle carte"
    },
};

let _isFirstConnect = true;

try {
    _isFirstConnect = localStorage.getItem('isFirstConnect') ? JSON.parse(localStorage.getItem('isFirstConnect')) : _isFirstConnect;
} catch (e) {
    // nothing to do ^^
}

const emptyStyleDefault = {
    marker: [],
    path: [],
    tooltip: [],
    popup: [],
    cluster: []
}

const defaultState = {
    api: null,
    fullMap: { ..._defaultMap },
    languages: [
        { label: 'en', value: 'en' },
        { label: 'fr', value: 'fr' },
        { label: 'it', value: 'it' },
    ],
    defaultLanguage: 'en',
    label: {},
    bounds: 'auto',
    center: null,
    zoom: null,
    raster: 'default',
    controls: {
        layerswitcher: false,
        tilesetswitcher: false,
        zoom: true,
        fullscreen: false,
        reset: false
    },
    defaults: {
        styles: {
            marker:
            {
                default: {
                    size: 60,
                    anchor: null,
                    layers: [{
                        id: 'layer_1234',
                        type: "symbole",
                        symbole: "marker2_plain",
                        fill: "rgba(74, 144, 226, 1)",
                        opacity: 1,
                        ratio: 1,
                        anchor: null,
                        stroke: { "color": "#fff", "width": 10 }
                    }],
                    letter: ""
                }
            },
            tooltip:
            {
                name: 'Tooltip par défault',
                background: 'white',
                fontSize: 16,
                fontFamily: 'Arial',
                color: '#000',
                paddingTop: 8,
                paddingRight: 8,
                paddingBottom: 8,
                paddingLeft: 8,
                shadowSize: 3,
                shadowOpacity: 3,
                pointerSize: 3
            },
            popup:
            {
                name: 'Popup par default',
                borderWitdh: '2px',
                borderRadius: '6px',
                bordercolor: '#000',
                fontSize: 16,
                fontFamily: 'Arial',
                color: '#000',
                paddingTop: 8,
                paddingRight: 8,
                paddingBottom: 8,
                paddingLeft: 8,
                shadowSize: 3,
                shadowOpacity: 3,
                pointerSize: 3,
                label: true,
                baseline: true,
                intro: true,
                text: true,
                media: true,
                link: true,
            },
            path:
            {
                "default": {
                    "width": 3,
                    "color": "#EF5858",
                    "dash": [3, 4],
                    "cap": "square"
                },
                "hover": {
                    "width": 4,
                    "color": "#EF5858",
                    "dash": [4, 8],
                    "cap": "square"
                },
                "select": {
                    "width": 4,
                    "color": "#FF000A",
                    "dash": [4, 8],
                    "cap": "square"
                }
            },
            cluster:
            {
                default: true,
                collection: [
                    {
                        from: 2,
                        style: {
                            size: 30,
                            layers: [
                                { id: 'layer_1235', type: "shape", shape: "circle", fill: 'hsla(100, 93%, 61%, 0.8)', stroke: { color: 'rgba(255,255,255,0.5)', width: 4 } }
                            ],
                            letter: { color: "#fff", font: "bold 12px arial" }
                        }
                    },
                    {
                        from: 4,
                        style: {
                            size: 30,
                            layers: [
                                { id: 'layer_1485', type: "shape", shape: "circle", fill: 'hsla(122, 45%, 47%, 0.8)', stroke: { color: 'rgba(255,255,255,0.5)', width: 4 } }
                            ],
                            letter: { color: "#fff", font: "bold 12px arial" }
                        }
                    },
                    {
                        from: 6,
                        style: {
                            size: 30,
                            layers: [
                                { id: 'layer_1268434', type: "shape", shape: "circle", fill: 'hsla(40, 94%, 44%, 0.8)', stroke: { color: 'rgba(255,255,255,0.5)', width: 4 } }
                            ],
                            letter: { color: "#fff", font: "bold 12px arial" }
                        }
                    },
                    {
                        from: 10,
                        style: {
                            size: 30,
                            layers: [
                                { id: 'layer_12346684', type: "shape", shape: "circle", fill: 'hsla(0, 83%, 64%, 0.8)', stroke: { color: 'rgba(255,255,255,0.5)', width: 4 } }
                            ],
                            letter: { color: "#fff", font: "bold 12px arial" }
                        }
                    },
                ]
            }
        }
    },
    features: [
        {
            "id": 1648030933286,
            "type": "marker",
            "visible": true,
            "style": null,
            "tooltip": {
                "visible": true,
                "style": null
            },
            "popup": {
                "visible": true,
                "style": null
            },
            "label": {
                "fr": "Paris"
            },
            "baseline": {
                "fr": "Île-de-France, France métropolitaine, France"
            },
            "coordinate": [
                48.8566969,
                2.3514616
            ]
        }
    ],
    panels: {
        position: {
            mobile: 'bottom',
            tablet: 'left',
            desktop: 'left'
        },
        scroll: {
            tablet: true,
            desktop: true
        },
        static: [],
        select: []
    },
    styles: {
        marker: [],
        path: [],
        cluster: [],
        popup: [],
        tooltip: [],
    },
    undoLog: [],
    redoLog: [],
    isFirstConnect: _isFirstConnect,
    imagePicker: {
        endpoint: "https://hapi.mmcreation.com/assets/vendors/filemanager/dialog.php?HID=0",
        connector: 'filemanager',
        token: "fea55609-e626b3a7-bdd59f85-c0233e6c-0c71f449-eb731640-094b706b-f1d13dd4"
    },
    isProUser: false,
    hapimap: null,
    reloadMap: 0,
    restartMap: 0
};

// ---------------------------------------------------
// Context provider declaration
// ---------------------------------------------------
const StateContext = React.createContext();
const DispatchContext = React.createContext();

const EditorProvider = ({ children }) => {
    const [state, dispatch] = useImmer({ ...defaultState });

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

// ---------------------------------------------------
// Context usage function declaration
// ---------------------------------------------------
function useStateContext() {
    const state = useContext(StateContext);

    if (state === undefined) {
        throw new Error("Ut oh, where is my editor state?");
    }

    return state;
}

function useDispatchContext() {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const [proccessTimeout, setProccessTimeout] = useState(null)

    if (state === undefined) {
        throw new Error("Ut oh, where is my editor state?");
    }

    if (dispatch === undefined) {
        throw new Error("Ut oh, where is my editor dispatch?");
    }

    function fixMissingIdInFeatures(features) {
        if (features?.length > 0 || features?.size > 0) {
            features.forEach((f, i) => {
                if (['layer', 'marker', 'path'].indexOf(f.type) >= 0) {
                    f.id = f.id || (performance.now().toString(36) + Math.random().toString(36)).replace(/\./g, "");;
                    if (f?.features?.length >= 0) {
                        f.features = fixMissingIdInFeatures(f.features);
                    }
                }
            });
        }
        return features;
    }

    function fixMapDefaults(defaults) {
        console.log(defaultState);

        if (defaults?.constructor.name !== 'Object')
            defaults = {};

        defaults.styles = defaults?.styles || deepCopy(defaultState.defaults.styles);
        defaults.styles.marker = defaults.styles?.marker || deepCopy(defaultState.defaults.styles.marker);
        defaults.styles.tooltip = defaults.styles?.tooltip || deepCopy(defaultState.defaults.styles.tooltip);
        defaults.styles.popup = defaults.styles?.popup || deepCopy(defaultState.defaults.styles.popup);
        defaults.styles.path = defaults.styles?.path || deepCopy(defaultState.defaults.styles.path);
        defaults.styles.cluster = defaults.styles?.cluster || deepCopy(defaultState.defaults.styles.cluster);

        console.log(defaults);

        return defaults
    }

    function handleData(data) {

        let defaultLanguage = data.defaultLanguage || (state.languages ? state.languages[0].value : defaultState.defaultLanguage);
        if (state && state.hapimap)
            state.hapimap.setLanguage(draft.defaultLanguage);

        let map = data.settings || {};
        dispatch(draft => {
            draft.fullMap = { ...map };
            draft.label = map?.label ? { ...map.label } : { fr: 'Nouvelle carte' };
            draft.raster = map?.raster || defaultState.raster;
            draft.bounds = map?.bounds || defaultState.bounds;
            draft.center = map?.center || defaultState.center;
            draft.zoom = map?.zoom || defaultState.zoom;
            draft.controls = map?.controls || defaultState.controls;
            draft.styles = map?.styles || { ...emptyStyleDefault };
            draft.features = fixMissingIdInFeatures(map?.features || []);
            draft.panels = map?.panels || defaultState.panels;
            draft.defaults = fixMapDefaults(map?.defaults);
            draft.restartMap = (new Date()).getTime()
            draft.imagePicker = data?.imagePicker ? data.imagePicker : null
            draft.api = data?.api ? data.api : null
            draft.languages = data?.languages ? data.languages : defaultState.languages;
            draft.defaultLanguage = defaultLanguage
        });
    }

    function loadSettings(settings) {

        let map = JSON.parse(settings)
        dispatch(draft => {
            draft.label = map?.label ? { ...map.label } : { fr: 'Nouvelle carte' };
            draft.raster = map?.raster || defaultState.raster;
            draft.bounds = map?.bounds || defaultState.bounds;
            draft.center = map?.center || defaultState.center;
            draft.zoom = map?.zoom | defaultState.zoom;
            draft.controls = map?.controls || defaultState.controls;
            draft.styles = map?.styles || { ...emptyStyleDefault };
            draft.features = fixMissingIdInFeatures(map?.features || []);
            draft.panels = map?.panels || defaultState.panels;
            draft.defaults = fixMapDefaults(map?.defaults);
            draft.reloadMap = (new Date()).getTime()
        });

    }

    function setFeatures(features) {

        dispatch(draft => {
            draft.features = [...features];
        });
    }

    function setStyles(styles) {
        dispatch(draft => {
            draft.styles = { ...styles };
            draft.reloadMap = (new Date()).getTime()
        });
    }



    function deleteFeature(id) {
        // pushToUndo()
        let newFeatures = JSON.parse(JSON.stringify([...state.features]))
        newFeatures = deleteFeatureById(newFeatures, id)
        dispatch(draft => {
            draft.features = [...newFeatures];
            draft.reloadMap = (new Date()).getTime()
        });
    }

    function deleteFeatureById(featuresArray, id) {
        return featuresArray.reduce((allFeatures, current) => {
            if (current.id === id) {
                return [...allFeatures];
            } else if (current.type === 'layer') {
                current.features = deleteFeatureById(current.features, id);
            } else if (current.type === 'path') {
                current.steps = deleteFeatureById(current.steps, id);
            } else if (current.type === 'step' && current.marker.id === id) {
                return [...allFeatures];
            }
            return [...allFeatures, current];
        }, [])
    }

    function addFeature(newFeature) {
        // pushToUndo()
        let newFeatures = JSON.parse(JSON.stringify([...state.features]))
        newFeatures = [...newFeatures, newFeature]

        if (state.hapimap) {
            state.hapimap.addPoint(newFeature)
        }

        dispatch(draft => {
            draft.features = [...newFeatures];
        });
    }


    function updateFeature(updatedFeature, forceReload = false, features = null, updateSteps = true) {
        // if (updateSteps)
        // pushToUndo()
        let newFeatures = features ? deepCopy(features) : deepCopy([...state.features])
        newFeatures = updateFeaturesArray(newFeatures, updatedFeature)
        if (state.hapimap && !forceReload) {
            state.hapimap.updatePoint(updatedFeature)
        }

        dispatch(draft => {
            if (updatedFeature.type === 'step' || forceReload) {
                draft.reloadMap = (new Date()).getTime()
            }
            draft.features = [...newFeatures];
        });
    }

    function updateFeatureOnDrag(targetId, feature, position) {
        // console.log(targetId, feature, position)
        // pushToUndo()
        dispatch(draft => {
            draft.features = updateDrop([...draft.features], feature, targetId, position);
            draft.reloadMap = (new Date()).getTime()
        });
    }

    function updateDrop(features, feature, targetId, position) {
        let newFeatures = JSON.parse(JSON.stringify([...features]))

        let result = updateAfterDrop([...newFeatures], feature, targetId, position)


        if (!targetId) {
            let isStep = !!feature.marker

            let newItem = isStep ? feature.marker : feature;
            //console.log('ni', newItem)
            result = insertNewFeature(result, newItem, position)
        }

        return result;
    }

    function updateAfterDrop(features, feature, targetId, position) {
        let isStep = !!feature.marker
        return features.reduce((array, item) => {
            if (item.id !== feature.id) {
                if (item.type === 'layer') {
                    item.features = [...updateAfterDrop(item.features, feature, targetId, position)]
                    if (item.id === targetId) {
                        let newItem = isStep ? feature.marker : feature;
                        //console.log('ni', newItem)
                        item.features = insertNewFeature(item.features, newItem, position)
                    }
                } else if (item.type === 'path') {
                    item.steps = [...updateAfterDrop(item.steps, feature, targetId, position)]
                    if (item.id === targetId) {
                        let newItem = isStep ? feature : createStep(feature);
                        if (position?.index === 0) {
                            newItem.waypoint = false;
                        }
                        item.steps = insertNewFeature(item.steps, newItem, position)
                    }
                }
                return [...array, item]
            } else {
                return array
            }
        }, [])
    }

    function insertNewFeature(features, newItem, position) {
        if (!position) {
            return [...features, newItem]
        }
        console.log('features', features, newItem)
        return features.reduce((accumulator, current) => {
            if (current.id !== position.id) {
                return [...accumulator, current];
            }
            return position.dropAfter ? [...accumulator, current, newItem] : [...accumulator, newItem, current]

        }, [])

    }

    function createStep(marker) {
        return {
            id: (new Date()).getTime(),
            vehicle: 'foot',
            waypoint: false,
            label: marker?.label,
            baseline: marker?.baseline,
            media: marker?.media,
            text: marker?.text,
            intro: marker?.intro,
            marker: marker,
            type: 'step'
        }
    }

    function updateFeaturesArray(featuresArray, updatedFeature) {
        return featuresArray.reduce((allFeatures, current) => {
            if (current.id === updatedFeature.id) {
                current = updatedFeature
            } else if (current.type === 'layer') {
                current.features = updateFeaturesArray(current.features, updatedFeature);
            } else if (current.type === 'path') {
                current.steps = updateFeaturesArray(current.steps, updatedFeature);
            }
            if (current.type === 'step' && current.marker.id === updatedFeature.id) {
                current.marker = updatedFeature
            }
            // console.log(current)
            return [...allFeatures, current];
        }, [])
    }

    function getFlatFeaturesArray(features) {
        return features.reduce((accumulator, current) => {
            let children = []
            if (current.features) {
                children = getFlatFeaturesArray(current.features)
            }
            if (current.steps) {
                children = getFlatFeaturesArray(current.steps)
            }
            if (current.type === 'step') {
                children = [current.marker]
            }
            return [...accumulator, current, ...children];
        }, [])
    }

    function canWaypoint(id) {
        let features = getFlatFeaturesArray(state.features)
        let index = 0;
        features.find(item => {
            if (item.type === 'path') {
                let foundIndex = item.steps.findIndex(step => step.id === id)
                if (foundIndex) {
                    index = foundIndex === item.length - 1 ? 0 : foundIndex
                    return true;
                }
            }
            return false;
        })
        return index;
    }

    function getFeatureName(id) {
        let features = getFlatFeaturesArray(state.features)
        if (features.length) {
            let feat = features.find(item => item.id === id)
            //console.log(feat)
            if (feat)
                return feat.label.fr || feat.label.en
        }
        return id;
    }

    function isFeatureValid(feature) {

        if (!feature.type || !feature.id) return false;

        if (feature.type !== 'layer' && !feature.style) return false;
        if (feature.type === 'api' && !feature?.api?.endpoint) return false;
        if (feature.type === 'marker' && !isMarkerValid(feature)) return false;

        if (feature.type === 'path' && !areStepsValid(feature.steps)) return false;

        return true;
    }

    function isMarkerValid(marker) {
        if (!marker.id || !marker.type || !marker.style || !marker.coordinate) return false

        return true;
    }

    function areStepsValid(steps) {
        if (steps.length < 2) return false;
        let response = true;

        steps.map(step => {
            if (!isMarkerValid(step.marker)) response = false;
        })
        return response;
    }

    function addPanel(panel, type) {
        // pushToUndo()
        dispatch(draft => {
            draft.panels = { ...draft.panels, [type]: [...draft.panels[type], panel] };
            draft.reloadMap = (new Date()).getTime()
        });
    }

    function updatePanel(panel, type) {
        // pushToUndo()
        dispatch(draft => {
            draft.panels = {
                ...draft.panels,
                [type]: draft.panels[type].map(item => item.id === panel.id ? panel : item)
            };
            draft.reloadMap = (new Date()).getTime()
        });
    }

    function updatePanelsProperty(data) {
        // pushToUndo()
        dispatch(draft => {
            draft.panels = { ...draft.panels, ...data };
            draft.reloadMap = (new Date()).getTime()
        });
    }

    function deletePanel(id, type) {
        // pushToUndo()
        dispatch(draft => {
            draft.panels = { ...draft.panels, [type]: draft.panels[type].filter(item => item.id !== id) };
            draft.reloadMap = (new Date()).getTime()
        });
    }

    function pushToUndo() {
        let lastStep = state.undoLog[state.undoLog.length - 1];
        let newStep = JSON.stringify(buildMapJson())
        if (lastStep === newStep || lastLog !== 'push') {
            lastLog = 'push'
            return;
        }
        lastLog = 'push'
        dispatch(draft => {
            draft.undoLog = [...draft.undoLog, newStep];
            draft.redoLog = [];
        })
    }

    function undo() {
        if (state.undoLog.length) {
            let undoList = [...state.undoLog]
            let currentState = undoList.pop()
            let newState = undoList[undoList.length - 1]
            lastLog = 'undo'
            dispatch(draft => {
                draft.undoLog = [...undoList];
                draft.redoLog = [...draft.redoLog, currentState];
            })
            loadSettings(newState);
        }
    }

    function redo() {
        if (state.redoLog.length) {
            let redoList = [...state.redoLog]
            let newData = redoList.pop()
            lastLog = 'redo'
            dispatch(draft => {
                draft.redoLog = [...redoList];
                draft.undoLog = [...draft.undoLog, newData];
            })
            loadSettings(newData);
        }
    }

    function checkStyles() {
        let features = getFlatFeaturesArray(state.features)
        let styleIds = findUsedStyle(features);
        //console.log(styleIds)
        dispatch(draft => {
            draft.styles = {
                ...draft.styles,
                marker: [...draft.styles.marker.filter(item => styleIds.includes(item.id))],
                path: [...draft.styles.path.filter(item => styleIds.includes(item.id))]
            };
        });
    }

    function findUsedStyle(features) {
        return features.reduce((accumulator, item) => {
            if (item.type === 'marker') {
                return [...accumulator, item.style]
            } else if (item.type === 'path') {
                return [...accumulator, item.style.marker, item.style.path, item.style.waypoint]
            } else if (item.type === 'layer') {
                return [...accumulator, item.style.marker]
            }
        }, [])
    }


    function resetMap() {
        setMap(this.state.fullMap)
    }

    function restartMap() {
        dispatch(draft => {
            draft.restartMap = (new Date()).getTime()
        })
    }

    function setter(key, value) {
        // pushToUndo()
        // console.log(value)
        dispatch(draft => {
            draft[key] = value;
            draft.reloadMap = (new Date()).getTime()
        })
    }

    function setIsFirstConnect(value) {
        dispatch(draft => {
            draft.isFirstConnect = value;
        })
        localStorage.setItem('isFirstConnect', value);
    }

    function buildMapJson(panels = true) {
        return {
            ...state.fullMap,
            bounds: state.bounds,
            zoom: state.zoom,
            center: state.center,
            defaults: state.defaults,
            raster: state.raster,
            controls: { ...state.controls },
            styles: deepCopy({ ...state.styles }),
            features: deepCopy([...state.features]),
            panels: panels ? deepCopy({ ...state.panels }) : null
        }
    }

    function deepCopy(object) {
        if (typeof object === "undefined")
            return object
        return JSON.parse(JSON.stringify(object));
    }

    function updateStyle(type, newStyle, isDefault) {
        // pushToUndo()
        if (!isDefault) {
            updateFeatureStyle(type, newStyle)
        } else {
            updateDefaultStyle(type, newStyle)
        }
    }

    function updateFeatureStyle(type, newStyle) {
        let _styles = deepCopy({ ...state.styles });
        let typeStyles = [..._styles[type].filter(item => item.id !== newStyle.id), newStyle]
        dispatch(draft => {
            draft.styles = { ..._styles, [type]: [...typeStyles] };
            draft.reloadMap = (new Date()).getTime()
        });
    }

    function updateDefaultStyle(type, newStyle) {
        let _defaults = deepCopy({ ...state.defaults });
        dispatch(draft => {
            draft.defaults = { ..._defaults, styles: { ..._defaults.styles, [type]: { ...newStyle } } };
            draft.reloadMap = (new Date()).getTime()
        });
    }

    function deleteStyle(type, id) {
        // pushToUndo()
        dispatch(draft => {
            draft.styles = { ...draft.styles, [type]: draft.styles[type].filter(item => item.id !== id) };
        });
    }

    function setMapInstance(map) {
        dispatch(draft => {
            draft.hapimap = map
        })
    }

    return {
        addFeature, addPanel, buildMapJson, canWaypoint,
        deepCopy, deleteFeature, deletePanel, deleteStyle,
        getFeatureName, getFlatFeaturesArray, handleData, isFeatureValid, pushToUndo, redo, resetMap, restartMap,
        setFeatures, setIsFirstConnect, setMapInstance, setStyles, setter, undo,
        updateFeature, updateFeatureOnDrag, updatePanel, updatePanelsProperty, updateStyle
    };
}

const useEditorContext = () => {
    return [useStateContext(), useDispatchContext()]
};

export { useEditorContext, EditorProvider, StateContext, DispatchContext };
