import React, { useEffect, useState } from "react";
import { FullscreenOutlined, SyncOutlined } from "@ant-design/icons";
import { useEditorContext } from '../../providers/EditorProvider';

export default function MiniMap({
  mapId = null,
  settings,
  forcePopup = false,
  forceTooltip = false,
}) {

  const [editorState, editorDispatch] = useEditorContext()
  const [previewJson, setPreviewJson] = useState(null);
  const [map, setMap] = useState(null);
  const [size, setSize] = useState("large");

  useEffect(() => {
    // dismount behavior
    return () => {
      if (map) {
        map.dismount();
      }
    };
  }, [map]);

  useEffect(() => {
    setPreviewJson(JSON.stringify(settings));
  }, [settings]);

  useEffect(() => {
    if (!previewJson) return;
    if (map) { map.load(previewJson); return; }
    initMap();
  }, [previewJson]);

  function initMap() {
    // instanciante a new map on DOM element through the loader helper
    //console.log('MiniMap Building');
    var hapimapLoader = new HapimapLoader("#" + mapId);

    hapimapLoader.onLoad(function (hapimap) {
      //console.log('MiniMap Loaded');
      // when the loader is ready we can start to interract to set behavior and load map content
      hapimap.on("map.load", (m) => {
        let [handler] = m.findHandlersByType('marker');
        if (handler && forcePopup) {
          handler.openPopup();
        }
        if (handler && forceTooltip) {
          handler.openTooltip();
        }

      });
      hapimap.on("raster.init", () => null);
      hapimap.on("raster.change", (raster) => null);
      hapimap.setLanguage(editorState.defaultLanguage);
      hapimap.load(previewJson);
      // after that  we can update the component state with the map reference
      setMap(hapimap);
    });
  }

  function handleReload() {
    if (map) map.reload();
  }

  function toggleSize() {
    setSize(prev => prev === 'default' ? 'large' : 'default')
    if (map) map.reload();
  }

  return (
    <div className={`minimap-preview ${size}`} >
      <div className="overlay">
        <div>Simulation de votre style</div>
        <div className="btn" onClick={toggleSize}>
          <FullscreenOutlined />
        </div>
        <div className="btn" onClick={handleReload}>
          <SyncOutlined />
        </div>
      </div>
      <div className="map" id={mapId}></div>
    </div>
  );
}
