import React from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { resolveFeatureIcon } from './helpers';
import LocationAutoComplete from './LocationAutoComplete'
import { useEditorContext } from '../../providers/EditorProvider';
import { PlusCircleOutlined } from '@ant-design/icons';

const baseData = (type) => {
    if (type === 'path') {
        return {
            label: { fr: 'Nouvel itinéraire' },
            steps: [],
            style: {
                marker: null,
                path: null,
                waypoint: null
            }
        }
    } else if (type === 'marker' || type === 'api') {
        return {
            style: null,
            tooltip: { visible: true, style: null },
            popup: { visible: true, style: null },
            label: { fr: 'Nouvelle référence api' }
        }
    } else if (type === 'layer') {
        return { label: { fr: 'Nouveaux dossier' }, features: [], cluster: true, style: { marker: null } }
    }
    return {}
}

export default function CreateFeatures() {

    const [editorState, editorDispatch] = useEditorContext()
    const { addFeature } = editorDispatch;

    
    const handleCreate = (data, type) => {
        let newFeature = {
            id: (new Date()).getTime(),
            type: type,
            visible: true,
            ...baseData(type),
            ...data,
        }

        addFeature(newFeature)
    }

    const addNewMarker = () => {
        let data = {
            label: { fr: "Nouveau marqueur" },
            coordinate: editorState.hapimap.getCenter()
        }

        handleCreate(data, 'marker')
    }

    const createMenu = (
        <Menu>
              <Menu.Item key='marker' onClick={addNewMarker}>
                <span className='mr-small'>{resolveFeatureIcon('marker')}</span>  Un marqueur
            </Menu.Item>
            <Menu.Item key='folder' onClick={() => handleCreate({}, 'layer')}>
                <span className='mr-small'>{resolveFeatureIcon('layer')}</span>  Un dossier
            </Menu.Item>
            <Menu.Item key='path' onClick={() => handleCreate({}, 'path')}>
            <span className='mr-small'>{resolveFeatureIcon('path')}</span> Un itinéraire
            </Menu.Item>
            {
                editorState.api &&
                <Menu.Item key='api' onClick={() => handleCreate({}, 'api')}>
                    <span className='mr-small'>{resolveFeatureIcon('api')}</span>  Une référence api
                </Menu.Item>
            }
        </Menu>
    )

    return (
        <div className='flex justify-between' style={{ paddingBottom: '1em' }}>
            <div style={{ width: '80%' }}>
                <LocationAutoComplete handleCreate={handleCreate} />
            </div>
            <Dropdown overlay={createMenu} placement="bottomRight" trigger="click">
                <Button type='primary' icon={<PlusCircleOutlined />} />
            </Dropdown>
        </div>
    )
}
